// position de module bandeau

.b-immo{
	position: relative;
	background-color: $blanc;
	box-sizing: border-box;
	padding: 25px;
	&__choix {
		& > div{
			width: 200px;
		}
	}
}