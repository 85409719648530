@font-face {
  font-family: 'Quicksand-SemiBold';
  src: url('../fonts/Quicksand-SemiBold.eot');
  src: url('../fonts/Quicksand-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Quicksand-SemiBold.svg#Quicksand-SemiBold') format('svg'),
  url('../fonts/Quicksand-SemiBold.ttf') format('truetype'),
  url('../fonts/Quicksand-SemiBold.woff') format('woff'),
  url('../fonts/Quicksand-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PTSans-Regular";
  src: url('../fonts/Quicksand-Regular.eot');
  src: url('../fonts/Quicksand-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Quicksand-Regular.svg#Quicksand-Regular') format('svg'),
  url('../fonts/Quicksand-Regular.ttf') format('truetype'),
  url('../fonts/Quicksand-Regular.woff') format('woff'),
  url('../fonts/Quicksand-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('../fonts/Quicksand-Regular.eot');
  src: url('../fonts/Quicksand-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Quicksand-Regular.svg#Quicksand-Regular') format('svg'),
  url('../fonts/Quicksand-Regular.ttf') format('truetype'),
  url('../fonts/Quicksand-Regular.woff') format('woff'),
  url('../fonts/Quicksand-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PTSans-Bold";
  src: url('../fonts/Quicksand-Bold.eot');
  src: url('../fonts/Quicksand-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Quicksand-Bold.svg#Quicksand-Bold') format('svg'),
  url('../fonts/Quicksand-Bold.ttf') format('truetype'),
  url('../fonts/Quicksand-Bold.woff') format('woff'),
  url('../fonts/Quicksand-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Black';
  src: url('../fonts/Quicksand-Bold.eot');
  src: url('../fonts/Quicksand-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Quicksand-Bold.svg#Quicksand-Bold') format('svg'),
  url('../fonts/Quicksand-Bold.ttf') format('truetype'),
  url('../fonts/Quicksand-Bold.woff') format('woff'),
  url('../fonts/Quicksand-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}