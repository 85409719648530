// position de module pied de page

.footer{
	&__row{
		background:$vert;
		border-radius:0 0 18px 18px;
		padding: 15px;
	}
}

.moduletable_footer-texte{
	background: rgba(246, 244, 241, 0.85);
	box-sizing: border-box;
	padding: 8px 25px;
	border-radius: 20px;
	margin-top:15px;
}
