.blog.l-infospratiques{
	background-color: $blanc;
	padding: 15px 0 25px 15px;
	.item{
		border-bottom: 1px solid $gris;
		margin-bottom: 15px;
		padding-bottom: 15px;
		clear: both;
		overflow: hidden;
	}
	.item-image{
		float: left;
		margin-right: 15px;
		img{
			width: 200px;
		}
	}
	h4{
		font-family: "PTSans-Bold";
		font-size: $seize;
		color: $gris;
		background-color: $gris-claire;
		padding: 10px 20px;
	}
	ul{
		list-style-type: none;
		padding-left: 30px;
		li{
			position: relative;
			&:before{
				content: '';
				position: absolute;
				left: -18px;
				top: 7px;
				width: 10px;
				height: 10px;
				border-radius: 15px;
				background-color: $vert;
			}
		}
	}
	a{
		color: $gris;
		text-decoration: underline;
	}
}