.b-modal-contact{
  h2{
    color: $noir;
    font-family: "PTSans-Bold";
  }
  .formBody{
    float: none;
  }
  .rsform-block-sujets {
    label{
      cursor: pointer;
    }
    .formBody {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 15px;

      .rsform-checkbox {
        position: relative;
        margin-right: 10px;
      }
    }
  }
  textarea{
    width: 100%;
    padding: 10px;
    border: 1px solid $gris;
  }
  .rsform-input-box{
    width: 100%;
  }
  .rsform-submit-button{
    margin: auto;
    display: table;
    text-transform: uppercase;
    font-family: "PTSans-Bold";
    font-size: $quatorze;
    padding: 10px 20px;
    background-color: $gris;
    color: $blanc;
    border: 0;
    text-decoration: none;
    transition: $transition;
    &:hover{
      transition: $transition;
      background-color: $vert;
      color: $noir;
    }
  }
  .rsform-block-mail{
    label{
      display: none;
    }
  }
}