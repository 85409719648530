.b-menusyndic{
  h3{
    font-size: $treize;
    margin: 0;
  }
  form{
    margin: 0;
  }
  label{
    display: none;
  }
  input, button{
    border: 0;
  }
  .champs{
    padding-left: 10px;
    @include media-breakpoint-down(sm) {
      padding-top: 10px;
      padding-left: 0;
    }
  }
  input {
    background: $entete;
    font-style: italic;
    color: $noir;
    font-size: $treize;
    margin: 0;
    width: 150px;
    padding: 1px 8px;
    box-shadow: none;
  }
  button {
    background-color: $vert;
    border-radius: 5px;
    color: $blanc;
    font-size: $treize;
    transition: all ease-in 0.2s;
    &:hover {
      background-color: $gris;
      transition: all ease-in 0.2s;
    }
  }
}