.view-syndic{
  main {
    .conteneur_login_coproprio {
      display: none;
    }
    #releve{
      padding: 15px 15px 25px;
    }
  }
  .l-syndic, main{
    background-color: $blanc;
    padding: 15px 15px 25px;
    h1{
      font-family: "PTSans-Bold";
      text-align: center;
      font-size: $vingt;
      color: $vert;
    }
    h2{
      font-family: "PTSans-Bold";
      text-transform: uppercase;
      font-size: $seize;
      color: $vert;
      margin: 0;
      span{
        color: $gris;
      }
    }
    h3{
      font-family: "PTSans-Bold";
      text-transform: uppercase;
      font-size: $quatorze;
      margin: 0;
      color: $vert;
      span{
        color: $gris;
      }
    }
    .nav{
      .nav-item{
        padding-left: 3px;
      }
      .nav-link{
        background-color: $gris;
        color: $blanc;
        transition: $transition;
        &:hover{
          background-color: $vert;
          color: $gris;
          transition: $transition;
        }
      }
    }
    table{
      width: 100%;
      border: 0;
      margin-top: 25px;
      thead{
        th{
          background-color: $gris_clair;
          padding: 5px 15px;
          border: 1px solid $noir;
          color: $blanc;
        }
      }
      tbody{
        tr{
          &:nth-child(2n){
            td{
              background-color: lighten($gris-claire,20%);
            }
          }
          td{
            background-color: lighten($vert,30%);
            &[colspan="2"]{
              background-color: $blanc;
              border: 0;
            }
          }
        }
      }
    }
    fieldset{
      border: 1px solid $gris-claire;
      padding: 15px;
      legend{
        display: inline-block;
        width: auto;
        max-width: auto;
        padding: 0 15px;
        color: $gris;
        font-size: $seize;
        position: relative;
        bottom: 2px;
      }
      h3{
        padding: 10px 0;
      }
      a{
        display: block;
        margin-left: 15px;
        color: $gris;
        text-decoration: underline;
        margin-bottom: 3px;
      }
    }
  }
}