.l-landing{
	background-color: $blanc;
	padding: 15px 15px 25px;
	@include media-breakpoint-down(sm) {
		padding: 15px;
	}
	h1{
		font-family: "PTSans-Bold";
		text-align: center;
		font-size: $vingt;
		color: $gris;
		span{
			display: block;
			font-size: $seize;
			color: $gris_clair;
		}
	}
	h2{
		font-family: "PTSans-Bold";
		text-transform: uppercase;
		font-size: $seize;
		color: $gris;
		background-color: $gris-claire;
		padding: 10px 20px;
	}
	h3{
		font-family: "PTSans-Bold";
		text-transform: uppercase;
		font-size: $quatorze;
		color: darken($gris,10%);
	}
	&__slogan{
		background-color: $vert;
		height: 260px;
		padding: 88px 52px;
		position: relative;
		h3{
			font-size: $vingt-cinq;
			text-align: center;
			line-height: 30px;
		}
	}
	&__slider{
		ul{
			li{
				position: absolute !important;
			}
		}
		@include media-breakpoint-up(lg) {
			height: 260px;
		}
	}
	&__contact{
		@include media-breakpoint-down(lg) {
			padding-top: 15px;
		}
		clear: both;
		input{
			width: 100%;
			margin-bottom: 10px;
			padding: 2px 10px;
			border: 1px solid $gris_clair;
			&[type="checkbox"]{
				position: relative;
				top: 5px;
			}
		}
		a{
			color: $vert;
		}
		button{
			border: 0;
			border-radius: 0;
			text-transform: uppercase;
			font-family: "PTSans-Bold";
			font-size: $quatorze;
			padding: 10px 20px;
			background-color: $gris;
			margin-top: 10px;
			color: $blanc;
			display: block;
			text-decoration: none;
			transition: $transition;
			&:hover{
				transition: $transition;
				background-color: $vert;
				color: $noir;
			}
		}
	}
	&__temoignage{
		@include media-breakpoint-down(lg) {
			div{
				&.w-35{
					position: relative !important;
					width: 100%;
					padding:10px;
					margin: 10px 10px 0;
					br{
						display: none;
					}
				}
			}
		}
	}
	.uk-accordion{
		padding: 0;
		& > li{
			&.uk-open{
				.uk-accordion-title{
					&:before{
						content: '';
						position: absolute;
						right: 20px;
						top: 35%;
						width: 20px;
						height: 20px;
						z-index: 1;
						background: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E) 50% 50% no-repeat;
						background-size: 60%;
						border: 2px solid $vert;
						padding: 5px;
					}
				}
			}
			&:before{
				content: none;
			}
			.uk-accordion-title{
				font-family: "PTSans-Bold";
				text-transform: uppercase;
				font-size: $seize;
				color: $gris;
				background-color: $gris-claire;
				padding: 10px 130px 10px 20px;
				text-decoration: none;
				position: relative;
				min-height: 67px;
				border: 1px solid $gris-claire;
				&:after{
					content: 'En savoir plus';
					display: table;
					z-index: 0;
					text-transform: initial;
					margin-top: 10px;
					background-color: $blanc;
					color: $vert;
					padding: 5px 10px;
					transition: $transition;
				}
				&:hover{
					&:after{
						background-color: $vert;
						color: $blanc;
						transition: $transition;
					}
				}
			}
			.uk-accordion-content{
				margin: 0;
				border: 1px solid $gris-claire;
				border-top: 0;
				padding: 30px 15px;
				a{
					background-color: $text-fonce;
					color: $vert;
					text-align: center;
					padding: 13px 40px;
					text-transform: uppercase;
					text-decoration: none;
					font-family: "PTSans-Bold";
					transition: $transition;
					display: table;
					&:hover{
						background-color: $gris;
						transition: $transition;
					}
				}
			}
		}
	}
	a.btn-contact{
		background-color: $text-fonce;
		color: $vert;
		text-align: center;
		padding: 13px 40px;
		text-transform: uppercase;
		text-decoration: none;
		font-family: "PTSans-Bold";
		transition: $transition;
		display: table;
		&:hover{
			background-color: $gris;
			transition: $transition;
		}
	}
	strong{
		color: $vert;
	}
}