// mise en forme des contenus articles
.contenu{
	clear:both;
	background:rgba(255,255,255,0.9);
	border-radius:15px 15px 0 0;
	border:1px solid $vert;
	padding:15px 25px;
	box-sizing:border-box;
	h2{
		font-size:1.4em;
		text-transform:uppercase;
		margin:0;
	}
	.item-page{
		.page-header{
			padding:0;
			margin:0;
			border:0;
			h2{
				text-transform:uppercase;
				color:$vert;
				font-size:1.65em;
				text-align:center;
				padding-bottom:25px;
				font-family:Myriad pro, Calibri, Sans-serif;
				line-height:unset !important;
				clear:both;
				span{
					font-weight:bold;
				}
			}
			h1{
				text-transform:uppercase;
				color:$vert;
				font-size:1.65em;
				text-align:center;
				padding-bottom:25px;
				font-family:Myriad pro, Calibri, Sans-serif;
				line-height:unset !important;
				clear:both;
				span{
					font-weight:bold;
				}
			}
		}
		div[itemprop="articleBody"]{
			color:$article;
			font-size:1.1em;
			text-align:justify;
			strong{
				color:$strong;
			}
			h2{
				color:$vert;
				font-size:1.1em;
				padding:25px 0 8px;
				font-family:Myriad pro, Calibri, Sans-serif;
				line-height:unset !important;
			}
			a{
				color:#000;
				text-decoration:underline;
				&.btn{
					background:url(../images/fleche.png) no-repeat 98% 50% $vert;
					border-radius:0;
					border:0;
					text-shadow:none;
					color:$gris;
					font-size:1.2em;
					font-weight:bold;
					padding:8px 50px 8px 10px;
					text-decoration:none;
					margin:30px 0;
					opacity:1;
					&:hover{
						opacity:0.8;
					}
				}
			}
			blockquote{
				border:0;
				margin:25px 0;
				padding:0;
				color:#000;
			}
		}
	}

  	#releve {
		position: relative;

	  	h2 {
		  	color: #a5ac2a;
		  	font-size: 1.1em;
		  	padding: 25px 0 8px;
		  	font-family: Myriad pro, Calibri, Sans-serif;
		  	text-transform: none;
		  	line-height: unset !important;
		}

	  	h3 {
			line-height: 20px;
	  	}

	  	th, .tot1 > td, .tot2 > td {
		  	background-color: #c7cf3a;
		}

	  	.tot2 {
		  	td {
			  	border-top: none;
			}
		}

		> a {
			float: right;
			margin: 0px 5px;
			color: #fff;
			background-color: #c7cf3a;
			padding: 5px 10px;
			border-radius: 5px;
			text-decoration: none;

			&:hover {
				background-color: #444444;
			}
		}

	  	fieldset {
			border: 1px solid grey;
		  	padding: 10px;
			legend {
			  	color: #a5ac2a;
			  	font-size: 1.1em;
			  	font-family: Myriad pro, Calibri, Sans-serif;
			  	padding: 0 10px;
			  	width: auto;
			  	border: none;
			  	margin: 0 5px;
			}

			a {
				cursor: pointer;
				display: block;
				background: url('../images/pdf_button.png') no-repeat 0 0;
				padding-left: 20px;
				margin-left: 20px;
				color: #000;
				text-decoration: none;

				&:hover {
					color: #c7cf3a;
				}
			}
	  	}
	}
}

.itemid-112{
	div[itemprop="articleBody"]{
		ul{
			li{
				margin:10px 0;
				strong{
					color:darken($vert,10%) !important;
				}
			}
		}
	}
}

.page-immo{
	.annonce{
		a{
			background-color:#fff;
			border:1px solid $gris_clair;
			padding:8px;
			box-sizing:border-box;

			img{
				border:2px solid $vert;
				box-sizing:border-box;
				width: 180px;
				height: 101px;
			}
			.description{
				background:none;
				text-align:left;
				color:$article;
				position:relative;
				.intro_annonce{
					display:block;
					background:url(../images/intro.png) no-repeat 95% 50% rgba(0,0,0,0.5);
					height:25px;
					position:absolute;
					top:-27px;
					left:2px;
					width:97%;
				}
			}
			opacity:1;
			&:hover{
				opacity:0.8;
			}
		}
	}
	.pagination{
		.pagination-list{
			box-shadow:none;
			li{
				a{
					border:0;
					background:none;
					color:$gris;
				}
				span{
					border:0;
					background:none;
					color:$gris;
				}
				&.active{
					span{
						background-color:$vert;
					}
				}
			}
		}
	}
	&.detail-annonce{
		h3{
			color:$vert;
			text-transform:uppercase;
			font-size:1.4em;
			line-height:20px;
			margin:0;
		}
		#prix{
			padding-bottom:15px;
		}
		.simulation_locataire{
			display:block;
			text-decoration: none;
			float:left;
			background:url(../images/fleche.png) no-repeat 98% 50% $vert;
			padding:8px 50px 8px 12px;
			color:$article;
			font-size:1.4em;
			font-weight:bold;
			opacity:1;
			&:hover{
				opacity:0.8;
			}
		}
		#annonce{
			padding-top:20px;
			#slider-photos{
				.slick-vertical{
					background-color:#fff;
					border:1px solid $vert;
					border-bottom-width:30px;
					border-top-width:30px;
					border-radius:13px;
				}
			}
		}
		#informations{
			font-weight:bold;
			font-size:1.4em;
			color:$vert;
			text-transform:uppercase;
		}
		#demande{
			a{
				background:url(../images/fleche.png) no-repeat 98% 50% $vert;
				border-radius:0;
				border:0;
				text-shadow:none;
				color:$gris;
				font-size:1.2em;
				font-weight:bold;
				padding:8px 50px 8px 10px;
				opacity:1;
				&:hover{
					opacity:0.8;
				}
			}
		}
		#details{
			background-color:$vert;
			padding:5px;
			box-sizing:border-box;
			[class*="span"]{
				h4{
					background-color:#fff;
					padding:4px 8px;
					margin:0;
				}
			}
		}
		.btn-return {
			background:url(../images/fleche-gauche.png) no-repeat 5% 50% $vert;
			text-decoration: none;
			border-radius:0;
			border:0;
			text-shadow:none;
			color:$gris;
			font-size:1.2em;
			font-weight:bold;
			padding: 8px 10px 8px 40px;
			box-shadow:none;
		}
	}
}
