
.l-simulateur{
	&__detail{
		background-color: $blanc;
		padding: 15px 15px 25px;
	}
	&__en-tete{
		a{
			text-transform: uppercase;
			font-family: "PTSans-Bold";
			font-size: $quatorze;
			padding: 10px 20px;
			background-color: $gris;
			color: $blanc;
			display: block;
			text-decoration: none;
			transition: $transition;
			&:hover{
				transition: $transition;
				background-color: $vert;
				color: $noir;
			}
		}
	}
	&__titre{
		h1{
			display: block;
			color: $vert;
			font-family: "PTSans-Bold";
			padding: 5px 15px;
			margin: 0 0 15px;
			font-size: $vingt;
			text-align: center;
		}
	}
	&__boutons{
		input{
			text-transform: uppercase;
			font-family: "PTSans-Bold";
			font-size: $quatorze;
			border: 0;
			padding: 10px 20px;
			background-color: $gris;
			margin:0 auto 10px;
			color: $blanc;
			display: block;
			text-decoration: none;
			transition: $transition;
			&:hover{
				transition: $transition;
				background-color: $vert;
				color: $noir;
			}
		}
	}
	&__simulation{
		input{
			width: 100%;
			padding: 5px 15px;
		}
		ul{
			list-style-type: none;
			padding-left: 30px;
			li{
				position: relative;
				&:before{
					content: '';
					position: absolute;
					left: -18px;
					top: 7px;
					width: 10px;
					height: 10px;
					border-radius: 15px;
					background-color: $vert;
				}
			}
		}
	}
}