.accueil{
  .b-immo{
    margin-bottom: 180px;
  }
}
.b-immo{
  h3{
    font-family: "PTSans-Regular";
    font-size: $vingt;
  }
  form{
    margin: 0;
  }
  input, .ms-parent button{
    background-color: lighten($gris-claire,10%);
    padding: 5px 15px;
    border: 0;
    //text-align: center;
    font-family: "PTSans-Regular";
  }
  input, .ms-parent ms-drop {
    background-color: white;
    border: 1px solid lighten($gris-claire,10%);
  }

  .libelle-budget{
    padding-right: 10px;
    position: relative;
    top: 3px;
  }
  &__libelle{
    position: relative;
    top: 5px;
  }
  button[type="submit"]{
    text-transform: uppercase;
    font-family: "PTSans-Bold";
    font-size: $quatorze;
    padding: 9px 20px 6px;
    background-color: $vert;
    border: 0;
    color: $blanc;
    display: block;
    text-decoration: none;
    transition: $transition;
    span{
      position: relative;
      bottom: 1px;
    }
    &:hover{
      transition: $transition;
      background-color: $vert;
      color: $noir;
    }
  }
  .ms-parent {
    width: 100% !important;
    button {
      border-radius: 0;
      height: 35px;
      span{
        top: 4px;
      }
    }
  }
  .ms-drop.bottom{
    width: 300px;
    ul{
      li{
        label{
          input{
            margin-right: 10px;
            position: relative;
            bottom: 2px;
          }
        }
      }
    }
  }
  .valeurs-critere{
    position: relative;
    top: 15px;
    .noUi-target{
      background: none;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      .noUi-base {
        .noUi-connects {
          background-color: $noir;
          height: 2px;
          border-radius: 2px;
          .noUi-connect {
            background-color: $noir;
          }
        }
        .noUi-origin {
          position: absolute;
          .noUi-handle {
            background-color: $vert;
            border-radius: 30px;
            width: 15px;
            height: 15px;
            border: 0;
            box-shadow: none;
            cursor: pointer;
            &.noUi-handle-lower{
              right: -15px;
            }
            &.noUi-handle-upper{
              right: -2px;
            }
            &:before, &:after{
              display: none;
            }
          }
        }
      }
    }
  }
}