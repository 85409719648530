@include ico {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icons, sans-serif !important; // stylelint-disable-line declaration-no-important
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1.5;
  color: inherit;
  text-transform: none;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    display: inline-block;
    font-family: icons, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;

    /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
    text-decoration: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:hover {
    text-decoration: none;
  }
}

@import "icons-generated";