// position de module navigation
.b-navigation{
  width: 100%;
  background-color: $vert;
  position: relative;
  padding: 15px 20px;
  @include media-breakpoint-down(lg) {
    position: fixed;
    right: 5px;
    top: 5px;
    height: 30px;
    background: none;
    z-index: 200;
    padding: 0;
    overflow: visible;
  }
  ul{
    li{
      &.active{
        a{
          color: $noir;
        }
      }
      a{
        color: $gris;
        transition: $transition;
        &:hover{
          color: $noir;
          transition: $transition;
        }
      }
    }
  }
}