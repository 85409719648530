.b-menusyndic{
	#syndicslide{
		background-color: $gris;
		color: $blanc;
		display: inline-block;
		padding: 2px 15px;
		font-size: $douze;
		text-decoration: none;
		transition: $transition;
		&:hover{
			background-color: $vert;
			color: $noir;
			transition: $transition;
		}
	}
}