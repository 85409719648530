
.l-annonce{
	.uk-slideshow-items{
		min-height: 280px !important;
		height: 484px;
		@include media-breakpoint-down(sm) {
			height: 280px;
		}
	}
	&__detail{
		background-color: $blanc;
		padding: 15px 15px 25px;
	}
	&__en-tete{
		a{
			text-transform: uppercase;
			font-family: "PTSans-Bold";
			font-size: $quatorze;
			padding: 10px 20px;
			background-color: $gris;
			color: $blanc;
			display: block;
			text-decoration: none;
			transition: $transition;
			&:hover{
				transition: $transition;
				background-color: $vert;
				color: $noir;
			}
		}
	}
	&__grande{
		height: 484px;
		@include media-breakpoint-down(sm) {
			height: 280px;
			background-size: contain;
		}
	}
	&__navigation{
		background-color: rgba($noir,0.8);
		padding: 10px 15px;
		color: $vert;
	}
	&__prix{
		background-color: rgba($noir,0.8);
		color: $blanc;
		font-size: 24px;
		@include media-breakpoint-down(lg) {
			font-size: 18px;
		}
	}
	&__miniature{
		li {
			margin-top: 15px;
			a {
				height: 96px;
				width: 96px;
				border: 2px solid $noir;
				margin: 0 auto;
				@include media-breakpoint-down(lg) {
					height: 60px;
					width: 60px;
				}
			}
			&.uk-active{
				a{
					border: 2px solid $vert;
				}
			}
		}
	}
	&__titre{
		background-color: $vert;
		h1, strong, span{
			display: block;
			color: $noir;
			font-family: "PTSans-Bold";
			padding: 5px 15px;

		}
		strong, span{
			font-size: $seize;
		}
		h1{
			border-bottom: 1px solid $noir;
			margin: 0;
			font-size: $vingt;
		}
		span{
			background-color: darken($vert,5%);
			border-bottom: 1px solid $noir;
		}
	}
	&__info{
		padding-top: 15px;
		color: $gris;
		text-align: justify;
	}
	&__details{
		padding: 15px 0;
		ul{
			list-style-type: none;
			padding: 0;
			margin: 0;
			li{
				h4{
					font-size: $quinze;
					font-weight: bold;
					margin: 0;
				}
				font-size: $treize;
				padding-bottom: 10px;
			}
		}
	}
	&__boutons{
		a{
			text-transform: uppercase;
			font-family: "PTSans-Bold";
			font-size: $quatorze;
			padding: 10px 20px;
			background-color: $gris;
			margin-bottom: 10px;
			color: $blanc;
			display: block;
			text-decoration: none;
			transition: $transition;
			&:hover{
				transition: $transition;
				background-color: $vert;
				color: $noir;
			}
		}
	}
	.photos{
		.uk-slideshow-items{
			@include media-breakpoint-down(lg) {
				min-height: 230px !important;
			}
		}
	}
}