// body

.body{
  overflow: hidden;
}

.category-module{
  @include media-breakpoint-down(sm) {
    top:160px;
  }
}