.l-contact .item-page{
	background-color: $blanc;
	padding: 15px 15px 25px;
	color: $gris;
	h1{
		font-family: "PTSans-Bold";
		text-align: center;
		font-size: $vingt;
		color: $gris;
	}
	a{
		color: $vert;
		text-decoration: underline;
	}
	form{
		overflow: hidden;
		margin: 0;
		.row{
			padding-bottom: 15px;
		}
		label{
			display: block;
		}
		input{
			width: 100%;
		}
		input, textarea{
			border: 1px solid $gris;
		}
		#jform_envoyer_copie{
			position: relative;
			top: 6px;
		}
		.btn-success{
			text-transform: uppercase;
			font-family: "PTSans-Bold";
			font-size: $quatorze;
			padding: 10px 20px;
			border: 0;
			border-radius: 0;
			background-color: $gris;
			color: $blanc;
			display: block;
			text-decoration: none;
			transition: $transition;
			float: right;
			&:hover{
				transition: $transition;
				background-color: $vert;
				color: $noir;
			}
		}
	}
}