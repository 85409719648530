.b-favorites {
  margin: 0 0 80px 0;
  .section-heading{
    background-color: $text-fonce;
    color: $vert;
    padding: 18px 20px;
    display: block;
    text-transform: uppercase;
    font-family: 'Lato-Black';
    font-size: $seize;
  }
  &__block {
    border: 1px solid $gris-claire;
    background-color: $blanc;
    text-align: center;

    a {
      img {
        height: 155px;
        width: 225px;
        margin: 10px;
        border: 2px solid $vert;
        object-fit: cover;
      }

      &:hover {
        text-decoration: none;
      }
    }

    &__description {
      border-radius: 0 0 10px 10px;
      padding: 10px;

      span {
        color: $text-fonce;
      }

      &__head{
        span{
          color: $text-fonce !important;
          font-family: "PTSans-Regular";
          font-size: $seize;
          letter-spacing: 0;
          line-height: 22px;
        }
      }
    }
  }
}
