.l-article{
	background-color: $blanc;
	padding: 15px 15px 25px;
	@include media-breakpoint-down(sm) {
		padding: 15px;
	}
	h1{
		font-family: "PTSans-Bold";
		text-align: center;
		font-size: $vingt;
		color: $gris;
	}
	h2{
		font-family: "PTSans-Bold";
		text-transform: uppercase;
		font-size: $seize;
		color: $gris;
		background-color: $gris-claire;
		padding: 10px 20px;
	}
	h3{
		font-family: "PTSans-Bold";
		text-transform: uppercase;
		font-size: $quatorze;
		color: darken($gris,10%);
	}
	ul{
		list-style-type: none;
		padding-left: 30px;
		li{
			position: relative;
			&:before{
				content: '';
				position: absolute;
				left: -18px;
				top: 7px;
				width: 10px;
				height: 10px;
				border-radius: 15px;
				background-color: $vert;
			}
		}
	}
	a{
		color: $gris;
		text-decoration: underline;
	}
	table{
		width: 100%;
		thead{
			td{
				background-color: darken($vert,2%);
				p{
					margin: 0;
				}
			}
		}
		tbody{
			td{
				background-color: lighten($gris-claire,15%);
			}
		}
	}
	em{
		color: $gris;
		font-size: $douze;
	}
}