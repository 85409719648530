// positions de module gauche et droite

.aside{
	background-color: $blanc;
	&__enfant{
		padding: 15px 15px 25px;
	}
	.moduletable_contact, .moduletable_temoignage, .moduletable_nous-trouver{
		border: 1px solid $gris-claire;
		padding: 5px;
		margin-bottom: 15px;
		h2{
			text-transform: uppercase;
			font-family: "PTSans-Bold";
			font-size: $quatorze;
			color: $vert;
			background-color: $gris;
			padding: 10px 20px;
			text-align: center;
		}
		a{
			background: url(../images/lien.png) no-repeat 95% center $gris;
			color: $blanc;
			display: block;
			text-transform: uppercase;
			font-size: $douze;
			padding: 5px 12px;
			transition: $transition;
			&:hover{
				transition: $transition;
				background: url(../images/lien.png) no-repeat 95% center $noir;
			}
		}
		em{
			color: $gris;
		}
		span{
			color: $vert;
		}
	}
}
