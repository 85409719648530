.b-enchiffres{
  h2{
    background-color: $gris-claire;
    font-family: "PTSans-Bold";
    color: $text-fonce;
    font-size: $vingt;
    display: block;
    padding: 18px 20px;
    text-transform: uppercase;
    margin-bottom: 25px;
  }
  p{
    margin: 0;
  }
  ul{
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 8% 20% 1fr 1fr 20%;
    gap: 35px;
    list-style-type: none;
    li{
      text-align: center;
      font-family: "PTSans-Regular";
      font-size: $dix-neuf;
      img{
        height: 70px;
        margin-bottom: 10px;
      }
      strong{
        font-size: $vingt-cinq;
        font-family: "PTSans-Bold";
        clear: both;
        display: block;
        width: 100%;
      }
    }
  }
}