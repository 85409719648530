.b-menufooter{
  display: table;
  margin: auto;
  a{
    color: $gris;
  }
  ul{
    li{
      &:before{
        content: '';
        display: inline-block;
        width: 4px;
        height: 1px;
        background-color: $gris;
        position: relative;
        bottom: 3px;
        margin: 0 10px;
      }
      &:first-child{
        &:before{
          content: none;
        }
      }
    }
  }
}