@charset "UTF-8";
/* syntax déclaration de variable

$variable : #000;

*/
/* ====== obligatoire
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #fff;
  --dark: #333333;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/* ====== optionnel
 */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-light hr {
    border-top-color: #f2f2f2; }
  .alert-light .alert-link {
    color: #6c6c6c; }

.alert-dark {
  color: #1b1b1b;
  background-color: #d6d6d6;
  border-color: #c6c6c6; }
  .alert-dark hr {
    border-top-color: #b9b9b9; }
  .alert-dark .alert-link {
    color: #020202; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #fff; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #333333; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1a1a1a; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-light:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #333333;
  border-color: #333333; }
  .btn-dark:hover {
    color: #fff;
    background-color: #202020;
    border-color: #1a1a1a; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #202020;
    border-color: #1a1a1a;
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1a1a;
    border-color: #131313; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 82, 82, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #fff;
  border-color: #fff; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-dark {
  color: #333333;
  border-color: #333333; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #333333;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #333333;
    border-color: #333333; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #fff !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important; }

.bg-dark {
  background-color: #333333 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1a1a1a !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #fff !important; }

.border-dark {
  border-color: #333333 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #fff !important; }

a.text-light:hover, a.text-light:focus {
  color: #d9d9d9 !important; }

.text-dark {
  color: #333333 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0d0d0d !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

/* ====== obligatoire
*/
/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Set `font-size` to support `rem` units
 *    Not using `font` property because a leading hyphen (e.g. -apple-system) causes the font to break in IE11 and Edge
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Style
 */
html {
  /* 1 */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  background: #fff;
  color: #666; }

/*
 * Remove the margin in all browsers.
 */
body {
  margin: 0; }

/* Links
 ========================================================================== */
/*
 * Remove the outline on focused links when they are also active or hovered
 */
a:active,
a:hover {
  outline: none; }

/*
 * Style
 */
a,
.uk-link {
  color: #1e87f0;
  text-decoration: none;
  cursor: pointer; }

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link,
.uk-link-toggle:focus .uk-link {
  color: #0f6ecd;
  text-decoration: underline; }

/* Text-level semantics
 ========================================================================== */
/*
 * 1. Add the correct text decoration in Edge.
 * 2. The shorthand declaration `underline dotted` is not supported in Safari.
 */
abbr[title] {
  /* 1 */
  text-decoration: underline dotted;
  /* 2 */
  -webkit-text-decoration-style: dotted; }

/*
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/*
 * 1. Consolas has a better baseline in running text compared to `Courier`
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Style
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-family: Consolas, monaco, monospace;
  /* 2 */
  font-size: 0.875rem;
  /* 3 */
  color: #f0506e;
  white-space: nowrap; }

/*
 * Emphasize
 */
em {
  color: #f0506e; }

/*
 * Insert
 */
ins {
  background: #ffd;
  color: #666;
  text-decoration: none; }

/*
 * Mark
 */
mark {
  background: #ffd;
  color: #666; }

/*
 * Quote
 */
q {
  font-style: italic; }

/*
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
 ========================================================================== */
/*
 * Remove the gap between embedded content and the bottom of their containers.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/*
 * 1. Add responsiveness.
 * 2. Auto-scale the height. Only needed if `height` attribute is present.
 * 3. Corrects responsive `max-width` behavior if padding and border are used.
 * 4. Exclude SVGs for IE11 because they don't preserve their aspect ratio.
 */
canvas,
img,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box; }

/* 4 */
@supports (display: block) {
  svg {
    max-width: 100%;
    height: auto;
    box-sizing: border-box; } }

/*
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/*
 * 1. Fix lazy loading images if parent element is set to `display: inline` and has `overflow: hidden`.
 * 2. Hide `alt` text for lazy loading images.
 * Note: Selector for background while loading img[data-src*='.jpg'][src*='data:image'] { background: grey; }
 */
img:not([src]) {
  /* 1 */
  min-width: 1px;
  /* 2 */
  visibility: hidden; }

/*
 * Iframe
 * Remove border in all browsers
 */
iframe {
  border: 0; }

/* Block elements
 ========================================================================== */
/*
 * Margins
 */
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 20px 0; }

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 20px; }

/* Headings
 ========================================================================== */
h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  margin: 0 0 20px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  color: #333;
  text-transform: none; }

/* Add margin if adjacent element */
* + h1, * + .uk-h1,
* + h2, * + .uk-h2,
* + h3, * + .uk-h3,
* + h4, * + .uk-h4,
* + h5, * + .uk-h5,
* + h6, * + .uk-h6,
* + .uk-heading-small,
* + .uk-heading-medium,
* + .uk-heading-large,
* + .uk-heading-xlarge,
* + .uk-heading-2xlarge {
  margin-top: 40px; }

/*
 * Sizes
 */
h1, .uk-h1 {
  font-size: 2.23125rem;
  line-height: 1.2; }

h2, .uk-h2 {
  font-size: 1.7rem;
  line-height: 1.3; }

h3, .uk-h3 {
  font-size: 1.5rem;
  line-height: 1.4; }

h4, .uk-h4 {
  font-size: 1.25rem;
  line-height: 1.4; }

h5, .uk-h5 {
  font-size: 16px;
  line-height: 1.4; }

h6, .uk-h6 {
  font-size: 0.875rem;
  line-height: 1.4; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  h1, .uk-h1 {
    font-size: 2.625rem; }
  h2, .uk-h2 {
    font-size: 2rem; } }

/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 30px; }

/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0; }

/* Description lists
 ========================================================================== */
dt {
  font-weight: bold; }

dd {
  margin-left: 0; }

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Show the overflow in Chrome, Edge and IE.
 * 2. Add the correct text-align in Edge and IE.
 * 3. Style
 */
hr, .uk-hr {
  /* 1 */
  overflow: visible;
  /* 2 */
  text-align: inherit;
  /* 3 */
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #e5e5e5; }

/* Add margin if adjacent element */
* + hr,
* + .uk-hr {
  margin-top: 20px; }

/* Address
 ========================================================================== */
address {
  font-style: normal; }

/* Blockquotes
 ========================================================================== */
blockquote {
  margin: 0 0 20px 0;
  font-size: 1.25rem;
  line-height: 1.5;
  font-style: italic; }

/* Add margin if adjacent element */
* + blockquote {
  margin-top: 20px; }

/*
 * Content
 */
blockquote p:last-of-type {
  margin-bottom: 0; }

blockquote footer {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.5; }

/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  font: 0.875rem / 1.5 Consolas, monaco, monospace;
  color: #666;
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto; }

pre code {
  font-family: Consolas, monaco, monospace; }

/* Selection pseudo-element
 ========================================================================== */
::selection {
  background: #39f;
  color: #fff;
  text-shadow: none; }

/* HTML5 elements
 ========================================================================== */
/*
 * 1. Add the correct display in Edge, IE 10+, and Firefox.
 * 2. Add the correct display in IE.
 */
details,
main {
  /* 2 */
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/*
 * Add the correct display in IE.
 */
template {
  display: none; }

/* Pass media breakpoints to JS
 ========================================================================== */
/*
 * Breakpoints
 */
.uk-breakpoint-s::before {
  content: "640px"; }

.uk-breakpoint-m::before {
  content: "960px"; }

.uk-breakpoint-l::before {
  content: "1200px"; }

.uk-breakpoint-xl::before {
  content: "1600px"; }

:root {
  --uk-breakpoint-s: 640px;
  --uk-breakpoint-m: 960px;
  --uk-breakpoint-l: 1200px;
  --uk-breakpoint-xl: 1600px; }

/* ====== optionnel
*/
/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0; }

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer; }

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: currentcolor; }

.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: currentcolor; }

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0); }

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle; }

/* Style modifiers
 ========================================================================== */
/*
 * Link
 */
.uk-icon-link {
  color: #999; }

.uk-icon-link:hover,
.uk-icon-link:focus {
  color: #666;
  outline: none; }

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #595959; }

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/* Hover + Focus */
.uk-icon-button:hover,
.uk-icon-button:focus {
  background-color: #ebebeb;
  color: #666;
  outline: none; }

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdfdf;
  color: #666; }

/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Allow cells to wrap into the next line
 * 2. Reset list
 */
.uk-grid {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none; }

/*
 * Grid cell
 * Note: Space is allocated solely based on content dimensions, but shrinks: 0 1 auto
 * Reset margin for e.g. paragraphs
 */
.uk-grid > * {
  margin: 0; }

/*
 * Remove margin from the last-child
 */
.uk-grid > * > :last-child {
  margin-bottom: 0; }

/* Gutter
 ========================================================================== */
/*
 * Default
 */
/* Horizontal */
.uk-grid {
  margin-left: -30px; }

.uk-grid > * {
  padding-left: 30px; }

/* Vertical */
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
  margin-top: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid {
    margin-left: -40px; }
  .uk-grid > * {
    padding-left: 40px; }
  /* Vertical */
  .uk-grid + .uk-grid,
  .uk-grid > .uk-grid-margin,
  * + .uk-grid-margin {
    margin-top: 40px; } }

/*
 * Small
 */
/* Horizontal */
.uk-grid-small,
.uk-grid-column-small {
  margin-left: -15px; }

.uk-grid-small > *,
.uk-grid-column-small > * {
  padding-left: 15px; }

/* Vertical */
.uk-grid + .uk-grid-small,
.uk-grid + .uk-grid-row-small,
.uk-grid-small > .uk-grid-margin,
.uk-grid-row-small > .uk-grid-margin,
* + .uk-grid-margin-small {
  margin-top: 15px; }

/*
 * Medium
 */
/* Horizontal */
.uk-grid-medium,
.uk-grid-column-medium {
  margin-left: -30px; }

.uk-grid-medium > *,
.uk-grid-column-medium > * {
  padding-left: 30px; }

/* Vertical */
.uk-grid + .uk-grid-medium,
.uk-grid + .uk-grid-row-medium,
.uk-grid-medium > .uk-grid-margin,
.uk-grid-row-medium > .uk-grid-margin,
* + .uk-grid-margin-medium {
  margin-top: 30px; }

/*
 * Large
 */
/* Horizontal */
.uk-grid-large,
.uk-grid-column-large {
  margin-left: -40px; }

.uk-grid-large > *,
.uk-grid-column-large > * {
  padding-left: 40px; }

/* Vertical */
.uk-grid + .uk-grid-large,
.uk-grid + .uk-grid-row-large,
.uk-grid-large > .uk-grid-margin,
.uk-grid-row-large > .uk-grid-margin,
* + .uk-grid-margin-large {
  margin-top: 40px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-large,
  .uk-grid-column-large {
    margin-left: -70px; }
  .uk-grid-large > *,
  .uk-grid-column-large > * {
    padding-left: 70px; }
  /* Vertical */
  .uk-grid + .uk-grid-large,
  .uk-grid + .uk-grid-row-large,
  .uk-grid-large > .uk-grid-margin,
  .uk-grid-row-large > .uk-grid-margin,
  * + .uk-grid-margin-large {
    margin-top: 70px; } }

/*
 * Collapse
 */
/* Horizontal */
.uk-grid-collapse,
.uk-grid-column-collapse {
  margin-left: 0; }

.uk-grid-collapse > *,
.uk-grid-column-collapse > * {
  padding-left: 0; }

/* Vertical */
.uk-grid + .uk-grid-collapse,
.uk-grid + .uk-grid-row-collapse,
.uk-grid-collapse > .uk-grid-margin,
.uk-grid-row-collapse > .uk-grid-margin {
  margin-top: 0; }

/* Divider
 ========================================================================== */
.uk-grid-divider > * {
  position: relative; }

.uk-grid-divider > :not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e5e5e5; }

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5; }

/*
 * Default
 */
/* Horizontal */
.uk-grid-divider {
  margin-left: -60px; }

.uk-grid-divider > * {
  padding-left: 60px; }

.uk-grid-divider > :not(.uk-first-column)::before {
  left: 30px; }

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px; }

.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider {
    margin-left: -80px; }
  .uk-grid-divider > * {
    padding-left: 80px; }
  .uk-grid-divider > :not(.uk-first-column)::before {
    left: 40px; }
  /* Vertical */
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
    margin-top: 80px; }
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    top: -40px;
    left: 80px; } }

/*
 * Small
 */
/* Horizontal */
.uk-grid-divider.uk-grid-small,
.uk-grid-divider.uk-grid-column-small {
  margin-left: -30px; }

.uk-grid-divider.uk-grid-small > *,
.uk-grid-divider.uk-grid-column-small > * {
  padding-left: 30px; }

.uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-small > :not(.uk-first-column)::before {
  left: 15px; }

/* Vertical */
.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin {
  margin-top: 30px; }

.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px;
  left: 30px; }

.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px; }

.uk-grid-divider.uk-grid-column-small.uk-grid-stack > .uk-grid-margin::before {
  left: 30px; }

/*
 * Medium
 */
/* Horizontal */
.uk-grid-divider.uk-grid-medium,
.uk-grid-divider.uk-grid-column-medium {
  margin-left: -60px; }

.uk-grid-divider.uk-grid-medium > *,
.uk-grid-divider.uk-grid-column-medium > * {
  padding-left: 60px; }

.uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-medium > :not(.uk-first-column)::before {
  left: 30px; }

/* Vertical */
.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px; }

.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px; }

.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px; }

.uk-grid-divider.uk-grid-column-medium.uk-grid-stack > .uk-grid-margin::before {
  left: 60px; }

/*
 * Large
 */
/* Horizontal */
.uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
  margin-left: -80px; }

.uk-grid-divider.uk-grid-large > *,
.uk-grid-divider.uk-grid-column-large > * {
  padding-left: 80px; }

.uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
  left: 40px; }

/* Vertical */
.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
  margin-top: 80px; }

.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
  left: 80px; }

.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px; }

.uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
  left: 80px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider.uk-grid-large,
  .uk-grid-divider.uk-grid-column-large {
    margin-left: -140px; }
  .uk-grid-divider.uk-grid-large > *,
  .uk-grid-divider.uk-grid-column-large > * {
    padding-left: 140px; }
  .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
  .uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
    left: 70px; }
  /* Vertical */
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
    margin-top: 140px; }
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px;
    left: 140px; }
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px; }
  .uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
    left: 140px; } }

/* Match child of a grid cell
 ========================================================================== */
/*
 * Behave like a block element
 * 1. Wrap into the next line
 * 2. Take the full width, at least 100%. Only if no class from the Width component is set.
 * 3. Expand width even if larger than 100%, e.g. because of negative margin (Needed for nested grids)
 */
.uk-grid-match > *,
.uk-grid-item-match {
  display: flex;
  /* 1 */
  flex-wrap: wrap; }

.uk-grid-match > * > :not([class*='uk-width']),
.uk-grid-item-match > :not([class*='uk-width']) {
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  /* 3 */
  flex: auto; }

/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #000; }

/* Hover + Focus */
.uk-close:hover,
.uk-close:focus {
  color: #666;
  outline: none; }

/* ========================================================================
   Component: Totop
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-totop {
  padding: 5px;
  color: #999; }

/* Hover + Focus */
.uk-totop:hover,
.uk-totop:focus {
  color: #666;
  outline: none; }

/* OnClick */
.uk-totop:active {
  color: #333; }

/* ========================================================================
   Component: Alert
 ========================================================================== */
.uk-alert {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 29px 15px 15px;
  background: #f8f8f8;
  color: #666; }

/* Add margin if adjacent element */
* + .uk-alert {
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-alert > :last-child {
  margin-bottom: 0; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-alert-close {
  position: absolute;
  top: 20px;
  right: 15px; }

/*
 * Remove margin from adjacent element
 */
.uk-alert-close:first-child + * {
  margin-top: 0; }

/*
 * Hover + Focus
 */
/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-alert-primary {
  background: #d8eafc;
  color: #1e87f0; }

/*
 * Success
 */
.uk-alert-success {
  background: #edfbf6;
  color: #32d296; }

/*
 * Warning
 */
.uk-alert-warning {
  background: #fef5ee;
  color: #faa05a; }

/*
 * Danger
 */
.uk-alert-danger {
  background: #fef4f6;
  color: #f0506e; }

/* ========================================================================
   Component: Badge
 ========================================================================== */
/*
 * 1. Style
 * 2. Center child vertically and horizontally
 */
.uk-badge {
  box-sizing: border-box;
  min-width: 22px;
  height: 22px;
  padding: 0 5px;
  border-radius: 0.25rem;
  vertical-align: middle;
  /* 1 */
  background: #1e87f0;
  color: #fff;
  font-size: 75%;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/*
 * Required for `a`
 */
.uk-badge:hover,
.uk-badge:focus {
  color: #fff;
  text-decoration: none;
  outline: none; }

/* ========================================================================
   Component: Overlay
 ========================================================================== */
.uk-overlay {
  padding: 30px 30px; }

/*
 * Remove margin from the last-child
 */
.uk-overlay > :last-child {
  margin-bottom: 0; }

/* Icon
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-overlay-default {
  background: rgba(255, 255, 255, 0.8); }

/*
 * Primary
 */
.uk-overlay-primary {
  background: rgba(34, 34, 34, 0.8); }

/* ========================================================================
   Component: Tab
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-tab {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -20px;
  /* 3 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-tab > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative; }

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center text if a width is set
 * 2. Style
 */
.uk-tab > * > a {
  /* 1 */
  display: block;
  text-align: center;
  /* 2 */
  padding: 5px 10px;
  color: #999; }

/* Hover + Focus */
.uk-tab > * > a:hover,
.uk-tab > * > a:focus {
  color: #666;
  text-decoration: none; }

/* Active */
.uk-tab > .uk-active > a {
  color: #333; }

/* Disabled */
.uk-tab > .uk-disabled > a {
  color: #999; }

/* Position modifier
 ========================================================================== */
/*
 * Bottom
 */
/*
 * Left + Right
 * 1. Reset Gutter
 */
.uk-tab-left,
.uk-tab-right {
  flex-direction: column;
  /* 1 */
  margin-left: 0; }

/* 1 */
.uk-tab-left > *,
.uk-tab-right > * {
  padding-left: 0; }

.uk-tab-left > * > a {
  text-align: left; }

.uk-tab-right > * > a {
  text-align: left; }

/* ========================================================================
   Component: Slidenav
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-slidenav {
  padding: 5px 10px;
  color: rgba(102, 102, 102, 0.5); }

/* Hover + Focus */
.uk-slidenav:hover,
.uk-slidenav:focus {
  color: rgba(102, 102, 102, 0.9);
  outline: none; }

/* OnClick */
.uk-slidenav:active {
  color: rgba(102, 102, 102, 0.5); }

/* Icon modifier
 ========================================================================== */
/*
 * Previous
 */
/*
 * Next
 */
/* Size modifier
 ========================================================================== */
.uk-slidenav-large {
  padding: 10px 10px; }

/* Container
 ========================================================================== */
.uk-slidenav-container {
  display: flex; }

/* ========================================================================
   Component: Dotnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-dotnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -12px; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 */
.uk-dotnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 12px; }

/* Items
 ========================================================================== */
/*
 * Items
 * 1. Hide text if present
 */
.uk-dotnav > * > * {
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(102, 102, 102, 0.2);
  /* 1 */
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap; }

/* Hover + Focus */
.uk-dotnav > * > :hover,
.uk-dotnav > * > :focus {
  background-color: rgba(102, 102, 102, 0.6);
  outline: none; }

/* OnClick */
.uk-dotnav > * > :active {
  background-color: rgba(102, 102, 102, 0.2); }

/* Active */
.uk-dotnav > .uk-active > * {
  background-color: rgba(102, 102, 102, 0.6); }

/* Modifier: 'uk-dotnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-dotnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -12px; }

/* 2 */
.uk-dotnav-vertical > * {
  padding-left: 0;
  padding-top: 12px; }

/* ========================================================================
   Component: Accordion
 ========================================================================== */
.uk-accordion {
  padding: 0;
  list-style: none; }

/* Item
 ========================================================================== */
.uk-accordion > :nth-child(n+2) {
  margin-top: 20px; }

/* Title
 ========================================================================== */
.uk-accordion-title {
  display: block;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #333; }

/* Hover + Focus */
.uk-accordion-title:hover,
.uk-accordion-title:focus {
  color: #666;
  text-decoration: none;
  outline: none; }

/* Content
 ========================================================================== */
.uk-accordion-content {
  display: flow-root;
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-accordion-content > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Dropdown
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  /* 3 */
  box-sizing: border-box;
  min-width: 10rem;
  /* 4 */
  padding: 15px;
  background: #f8f8f8;
  color: #212529; }

/* Show */
.uk-dropdown.uk-open {
  display: block; }

/* Nav
 * Adopts `uk-nav`
 ========================================================================== */
.uk-dropdown-nav {
  white-space: nowrap; }

/*
 * Items
 */
.uk-dropdown-nav > li > a {
  color: #999; }

/* Hover + Focus + Active */
.uk-dropdown-nav > li > a:hover,
.uk-dropdown-nav > li > a:focus,
.uk-dropdown-nav > li.uk-active > a {
  color: #666; }

/*
 * Header
 */
.uk-dropdown-nav .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-dropdown-nav .uk-nav-sub a {
  color: #999; }

.uk-dropdown-nav .uk-nav-sub a:hover,
.uk-dropdown-nav .uk-nav-sub a:focus,
.uk-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #666; }

/* Direction / Alignment modifiers
 ========================================================================== */
/* Direction */
[class*='uk-dropdown-top'] {
  margin-top: -10px; }

[class*='uk-dropdown-bottom'] {
  margin-top: 10px; }

[class*='uk-dropdown-left'] {
  margin-left: -10px; }

[class*='uk-dropdown-right'] {
  margin-left: 10px; }

/* Grid modifiers
 ========================================================================== */
.uk-dropdown-stack .uk-dropdown-grid > * {
  width: 100% !important; }

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(0, 0, 0, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px; } }

/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1; }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden; }

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. Fix `max-width: 100%` not working in combination with flex and responsive images in IE11
 *    `!important` needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  /* 3 */
  max-width: calc(100% - 0.01px) !important;
  /* 4 */
  background: #fff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform; }

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0); }

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px; }

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none; }

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0); }

/* Sections
 ========================================================================== */
.uk-modal-body {
  display: flow-root;
  padding: 30px 30px; }

.uk-modal-header {
  display: flow-root;
  padding: 15px 30px;
  background: #f8f8f8; }

.uk-modal-footer {
  display: flow-root;
  padding: 15px 30px;
  background: #f8f8f8; }

/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0; }

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.5; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*='uk-modal-close-'] {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px; }

/*
 * Remove margin from adjacent element
 */
[class*='uk-modal-close-']:first-child + * {
  margin-top: 0; }

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white; }

.uk-modal-close-outside:hover {
  color: #fff; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%); } }

/*
 * Full
 */
/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 * 7. Prevent cancellation of pointer events while dragging
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  /* 7 */
  touch-action: pinch-zoom; }

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1; }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden; }

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 *    Using `vh` and `vw` to make responsive image work in IE11
 * 6. Suppress outline on focus
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity; }

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh; }

/* 6 */
.uk-lightbox-items > :focus {
  outline: none; }

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto; }

.uk-lightbox-items > .uk-active {
  display: flex; }

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7); }

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7); }

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7); }

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff; }

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/*
 * Hover
 */
.uk-lightbox-button:hover {
  color: #fff; }

/* Caption
 ========================================================================== */
.uk-lightbox-caption:empty {
  display: none; }

/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%; }

/* ========================================================================
   Component: Slideshow
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slideshow {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Items
 ========================================================================== */
/*
 * 1. Create position and stacking context
 * 2. Reset list
 * 3. Clip child elements
 * 4. Prevent displaying the callout information on iOS.
 */
.uk-slideshow-items {
  /* 1 */
  position: relative;
  z-index: 0;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  overflow: hidden;
  /* 4 */
  -webkit-touch-callout: none; }

/* Item
 ========================================================================== */
/*
 * 1. Position items above each other
 * 2. Take the full width
 * 3. Clip child elements, e.g. for `uk-cover`
 * 4. Optimize animation
 * 5. Disable horizontal panning gestures in IE11 and Edge
 * 6. Suppress outline on focus
 */
.uk-slideshow-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
  /* 2 */
  right: 0;
  bottom: 0;
  /* 3 */
  overflow: hidden;
  /* 4 */
  will-change: transform, opacity;
  /* 5 */
  touch-action: pan-y; }

/* 6 */
.uk-slideshow-items > :focus {
  outline: none; }

/*
 * Hide not active items
 */
.uk-slideshow-items > :not(.uk-active) {
  display: none; }

/* ========================================================================
   Component: Slider
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slider {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Container
 ========================================================================== */
/*
 * 1. Clip child elements
 */
.uk-slider-container {
  /* 1 */
  overflow: hidden; }

/* Items
 ========================================================================== */
/*
 * 1. Optimize animation
 * 2. Create a containing block. In Safari it's neither created by `transform` nor `will-change`.
 */
.uk-slider-items {
  /* 1 */
  will-change: transform;
  /* 2 */
  position: relative; }

/*
 * 1. Reset list style without interfering with grid
 * 2. Prevent displaying the callout information on iOS.
 */
.uk-slider-items:not(.uk-grid) {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 2 */
  -webkit-touch-callout: none; }

.uk-slider-items.uk-grid {
  flex-wrap: nowrap; }

/* Item
 ========================================================================== */
/*
 * 1. Let items take content dimensions (0 0 auto)
 * 2. Create position context
 * 3. Disable horizontal panning gestures in IE11 and Edge
 * 4. Suppress outline on focus
 */
.uk-slider-items > * {
  /* 1 */
  flex: none;
  /* 2 */
  position: relative;
  /* 3 */
  touch-action: pan-y; }

/* 4 */
.uk-slider-items > :focus {
  outline: none; }

/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * 1. Resolve frame rate issues on devices with lower frame rates by forcing hardware acceleration
 */
.uk-sticky-fixed {
  z-index: 980;
  box-sizing: border-box;
  margin: 0 !important;
  /* 1 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

/*
 * Faster animations
 */
.uk-sticky[class*='uk-animation-'] {
  animation-duration: 0.2s; }

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s; }

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto; }

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  /* 2 */
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #222;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 40px 40px; } }

/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -270px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px; } }

/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0; }

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0; }

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out; }

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right; }

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out; }

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0; }

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0; }

.uk-open > .uk-offcanvas-reveal {
  width: 270px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 350px; } }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 1000;
  top: 20px;
  right: 20px;
  padding: 5px; }

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none; }

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear; }

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1; }

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden; }

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%; }

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 270px; }

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -270px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px; }
  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px; } }

/* ========================================================================
   Component: Switcher
 ========================================================================== */
/*
 * Reset list
 */
.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none; }

/* Items
 ========================================================================== */
/*
 * Hide not active items
 */
.uk-switcher > :not(.uk-active) {
  display: none; }

/*
 * Remove margin from the last-child
 */
.uk-switcher > * > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Position
 ========================================================================== */
/* Directions
 ========================================================================== */
/*
 * 1. Prevent content overflow if `max-width: 100%` is used inside position container.
 */
[class*='uk-position-top'],
[class*='uk-position-bottom'],
[class*='uk-position-left'],
[class*='uk-position-right'],
[class*='uk-position-center'] {
  position: absolute !important;
  /* 1 */
  max-width: 100%; }

/* Edges
 ========================================================================== */
/* Don't use `width: 100%` because it is wrong if the parent has padding. */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0; }

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0; }

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0; }

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0; }

/* Corners
 ========================================================================== */
.uk-position-top-left {
  top: 0;
  left: 0; }

.uk-position-top-right {
  top: 0;
  right: 0; }

.uk-position-bottom-left {
  bottom: 0;
  left: 0; }

.uk-position-bottom-right {
  bottom: 0;
  right: 0; }

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 */
.uk-position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 1 */
  width: max-content;
  max-width: 100%;
  box-sizing: border-box; }

/* Vertical */
[class*='uk-position-center-left'],
[class*='uk-position-center-right'] {
  top: 50%;
  transform: translateY(-50%); }

.uk-position-center-left {
  left: 0; }

.uk-position-center-right {
  right: 0; }

.uk-position-center-left-out {
  right: 100%;
  width: max-content; }

.uk-position-center-right-out {
  left: 100%;
  width: max-content; }

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
  left: 50%;
  transform: translateX(-50%);
  /* 1 */
  width: max-content;
  max-width: 100%;
  box-sizing: border-box; }

.uk-position-top-center {
  top: 0; }

.uk-position-bottom-center {
  bottom: 0; }

/* Cover
 ========================================================================== */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

/* Utility
 ========================================================================== */
.uk-position-relative {
  position: relative !important; }

.uk-position-absolute {
  position: absolute !important; }

.uk-position-fixed {
  position: fixed !important; }

.uk-position-z-index {
  z-index: 1; }

/* Margin modifier
 ========================================================================== */
/*
 * Small
 */
.uk-position-small {
  max-width: calc(100% - (15px * 2));
  margin: 15px; }

.uk-position-small.uk-position-center {
  transform: translate(-50%, -50%) translate(-15px, -15px); }

.uk-position-small[class*='uk-position-center-left'],
.uk-position-small[class*='uk-position-center-right'] {
  transform: translateY(-50%) translateY(-15px); }

.uk-position-small.uk-position-top-center,
.uk-position-small.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-15px); }

/*
 * Medium
 */
.uk-position-medium {
  max-width: calc(100% - (30px * 2));
  margin: 30px; }

.uk-position-medium.uk-position-center {
  transform: translate(-50%, -50%) translate(-30px, -30px); }

.uk-position-medium[class*='uk-position-center-left'],
.uk-position-medium[class*='uk-position-center-right'] {
  transform: translateY(-50%) translateY(-30px); }

.uk-position-medium.uk-position-top-center,
.uk-position-medium.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-30px); }

/*
 * Large
 */
.uk-position-large {
  max-width: calc(100% - (30px * 2));
  margin: 30px; }

.uk-position-large.uk-position-center {
  transform: translate(-50%, -50%) translate(-30px, -30px); }

.uk-position-large[class*='uk-position-center-left'],
.uk-position-large[class*='uk-position-center-right'] {
  transform: translateY(-50%) translateY(-30px); }

.uk-position-large.uk-position-top-center,
.uk-position-large.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-30px); }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-position-large {
    max-width: calc(100% - (50px * 2));
    margin: 50px; }
  .uk-position-large.uk-position-center {
    transform: translate(-50%, -50%) translate(-50px, -50px); }
  .uk-position-large[class*='uk-position-center-left'],
  .uk-position-large[class*='uk-position-center-right'] {
    transform: translateY(-50%) translateY(-50px); }
  .uk-position-large.uk-position-top-center,
  .uk-position-large.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-50px); } }

/* ========================================================================
   Component: Background
 ========================================================================== */
/* Color
 ========================================================================== */
.uk-background-default {
  background-color: #fff; }

.uk-background-muted {
  background-color: #f8f8f8; }

.uk-background-primary {
  background-color: #1e87f0; }

.uk-background-secondary {
  background-color: #222; }

/* Size
 ========================================================================== */
.uk-background-cover,
.uk-background-contain {
  background-position: 50% 50%;
  background-repeat: no-repeat; }

.uk-background-cover {
  background-size: cover; }

.uk-background-contain {
  background-size: contain; }

/* Position
 ========================================================================== */
.uk-background-top-left {
  background-position: 0 0; }

.uk-background-top-center {
  background-position: 50% 0; }

.uk-background-top-right {
  background-position: 100% 0; }

.uk-background-center-left {
  background-position: 0 50%; }

.uk-background-center-center {
  background-position: 50% 50%; }

.uk-background-center-right {
  background-position: 100% 50%; }

.uk-background-bottom-left {
  background-position: 0 100%; }

.uk-background-bottom-center {
  background-position: 50% 100%; }

.uk-background-bottom-right {
  background-position: 100% 100%; }

/* Repeat
 ========================================================================== */
.uk-background-norepeat {
  background-repeat: no-repeat; }

/* Attachment
 ========================================================================== */
/*
 * 1. Fix bug introduced in Chrome 67: the background image is not visible if any element on the page uses `translate3d`
 */
.uk-background-fixed {
  background-attachment: fixed;
  /* 1 */
  backface-visibility: hidden; }

/*
 * Exclude touch devices because `fixed` doesn't work on iOS and Android
 */
@media (pointer: coarse) {
  .uk-background-fixed {
    background-attachment: scroll; } }

/* Image
 ========================================================================== */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-background-image\@s {
    background-image: none !important; } }

/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-background-image\@m {
    background-image: none !important; } }

/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-background-image\@l {
    background-image: none !important; } }

/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-background-image\@xl {
    background-image: none !important; } }

/* Blend modes
 ========================================================================== */
.uk-background-blend-multiply {
  background-blend-mode: multiply; }

.uk-background-blend-screen {
  background-blend-mode: screen; }

.uk-background-blend-overlay {
  background-blend-mode: overlay; }

.uk-background-blend-darken {
  background-blend-mode: darken; }

.uk-background-blend-lighten {
  background-blend-mode: lighten; }

.uk-background-blend-color-dodge {
  background-blend-mode: color-dodge; }

.uk-background-blend-color-burn {
  background-blend-mode: color-burn; }

.uk-background-blend-hard-light {
  background-blend-mode: hard-light; }

.uk-background-blend-soft-light {
  background-blend-mode: soft-light; }

.uk-background-blend-difference {
  background-blend-mode: difference; }

.uk-background-blend-exclusion {
  background-blend-mode: exclusion; }

.uk-background-blend-hue {
  background-blend-mode: hue; }

.uk-background-blend-saturation {
  background-blend-mode: saturation; }

.uk-background-blend-color {
  background-blend-mode: color; }

.uk-background-blend-luminosity {
  background-blend-mode: luminosity; }

/* ========================================================================
   Component: Notification
 ========================================================================== */
/*
 * 1. Set position
 * 2. Dimensions
 */
.uk-notification {
  /* 1 */
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  /* 2 */
  box-sizing: border-box;
  width: 350px; }

/* Position modifiers
========================================================================== */
.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: 10px; }

.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: -175px; }

.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: 10px; }

/* Responsiveness
========================================================================== */
/* Phones portrait and smaller */
@media (max-width: 639px) {
  .uk-notification {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0; } }

/* Message
========================================================================== */
.uk-notification-message {
  position: relative;
  padding: 15px;
  background: #f8f8f8;
  color: #666;
  font-size: 1.25rem;
  line-height: 1.4;
  cursor: pointer; }

* + .uk-notification-message {
  margin-top: 10px; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-notification-close {
  display: none;
  position: absolute;
  top: 20px;
  right: 15px; }

.uk-notification-message:hover .uk-notification-close {
  display: block; }

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-notification-message-primary {
  color: #1e87f0; }

/*
 * Success
 */
.uk-notification-message-success {
  color: #32d296; }

/*
 * Warning
 */
.uk-notification-message-warning {
  color: #faa05a; }

/*
 * Danger
 */
.uk-notification-message-danger {
  color: #f0506e; }

/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Position
 * 3. Remove tooltip from document flow to keep the UIkit container from changing its size when injected into the document initially
 * 4. Dimensions
 * 5. Style
 */
.uk-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1030;
  /* 3 */
  top: 0;
  /* 4 */
  box-sizing: border-box;
  max-width: 200px;
  padding: 3px 6px;
  /* 5 */
  background: #666;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 0.875rem; }

/* Show */
.uk-tooltip.uk-active {
  display: block; }

/* Direction / Alignment modifiers
 ========================================================================== */
/* Direction */
[class*='uk-tooltip-top'] {
  margin-top: 0; }

[class*='uk-tooltip-bottom'] {
  margin-top: 0; }

[class*='uk-tooltip-left'] {
  margin-left: 0; }

[class*='uk-tooltip-right'] {
  margin-left: 0; }

/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*='uk-animation-'] {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: both; }

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear; }

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade-scale-02; }

.uk-animation-scale-down {
  animation-name: uk-fade-scale-18; }

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade-top; }

.uk-animation-slide-bottom {
  animation-name: uk-fade-bottom; }

.uk-animation-slide-left {
  animation-name: uk-fade-left; }

.uk-animation-slide-right {
  animation-name: uk-fade-right; }

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade-top-small; }

.uk-animation-slide-bottom-small {
  animation-name: uk-fade-bottom-small; }

.uk-animation-slide-left-small {
  animation-name: uk-fade-left-small; }

.uk-animation-slide-right-small {
  animation-name: uk-fade-right-small; }

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade-top-medium; }

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade-bottom-medium; }

.uk-animation-slide-left-medium {
  animation-name: uk-fade-left-medium; }

.uk-animation-slide-right-medium {
  animation-name: uk-fade-right-medium; }

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-scale-kenburns;
  animation-duration: 15s; }

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake; }

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  stroke-dasharray: var(--uk-animation-stroke);
  animation-duration: 2s; }

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in; }

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s; }

/* Toggle (Hover + Focus)
========================================================================== */
/*
 * The toggle is triggered on touch devices using `:focus` and tabindex
 */
.uk-animation-toggle:not(:hover):not(:focus) [class*='uk-animation-'] {
  animation-name: none; }

/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-animation-toggle {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/*
 * Remove outline for `tabindex`
 */
.uk-animation-toggle:focus {
  outline: none; }

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
 * Slide Top
 */
@keyframes uk-fade-top {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Bottom
 */
@keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Left
 */
@keyframes uk-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Right
 */
@keyframes uk-fade-right {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Top Small
 */
@keyframes uk-fade-top-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Bottom Small
 */
@keyframes uk-fade-bottom-small {
  0% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Left Small
 */
@keyframes uk-fade-left-small {
  0% {
    opacity: 0;
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Right Small
 */
@keyframes uk-fade-right-small {
  0% {
    opacity: 0;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Top Medium
 */
@keyframes uk-fade-top-medium {
  0% {
    opacity: 0;
    transform: translateY(-50px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Bottom Medium
 */
@keyframes uk-fade-bottom-medium {
  0% {
    opacity: 0;
    transform: translateY(50px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Left Medium
 */
@keyframes uk-fade-left-medium {
  0% {
    opacity: 0;
    transform: translateX(-50px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Right Medium
 */
@keyframes uk-fade-right-medium {
  0% {
    opacity: 0;
    transform: translateX(50px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Scale Up
 */
@keyframes uk-fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2); }
  100% {
    opacity: 1;
    transform: scale(1); } }

/*
 * Scale Down
 */
@keyframes uk-fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8); }
  100% {
    opacity: 1;
    transform: scale(1); } }

/*
 * Kenburns
 */
@keyframes uk-scale-kenburns {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.2); } }

/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0); }
  10% {
    transform: translateX(-9px); }
  20% {
    transform: translateX(8px); }
  30% {
    transform: translateX(-7px); }
  40% {
    transform: translateX(6px); }
  50% {
    transform: translateX(-5px); }
  60% {
    transform: translateX(4px); }
  70% {
    transform: translateX(-3px); }
  80% {
    transform: translateX(2px); }
  90% {
    transform: translateX(-1px); } }

/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke); }
  100% {
    stroke-dashoffset: 0; } }

/* ========================================================================
   Component: Width
 ========================================================================== */
/* Equal child widths
 ========================================================================== */
[class*='uk-child-width'] > * {
  box-sizing: border-box;
  width: 100%; }

.uk-child-width-1-2 > * {
  width: 50%; }

.uk-child-width-1-3 > * {
  width: calc(100% * 1 / 3.001); }

.uk-child-width-1-4 > * {
  width: 25%; }

.uk-child-width-1-5 > * {
  width: 20%; }

.uk-child-width-1-6 > * {
  width: calc(100% * 1 / 6.001); }

.uk-child-width-auto > * {
  width: auto; }

/*
 * 1. Reset the `min-width`, which is set to auto by default, because
 *    flex items won't shrink below their minimum intrinsic content size.
 *    Using `1px` instead of `0`, so items still wrap into the next line,
 *    if they have zero width and padding and the predecessor is 100% wide.
 */
.uk-child-width-expand > :not([class*='uk-width']) {
  flex: 1;
  /* 1 */
  min-width: 1px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-child-width-1-1\@s > * {
    width: 100%; }
  .uk-child-width-1-2\@s > * {
    width: 50%; }
  .uk-child-width-1-3\@s > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@s > * {
    width: 25%; }
  .uk-child-width-1-5\@s > * {
    width: 20%; }
  .uk-child-width-1-6\@s > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@s > * {
    width: auto; }
  .uk-child-width-expand\@s > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-child-width-1-1\@m > * {
    width: 100%; }
  .uk-child-width-1-2\@m > * {
    width: 50%; }
  .uk-child-width-1-3\@m > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@m > * {
    width: 25%; }
  .uk-child-width-1-5\@m > * {
    width: 20%; }
  .uk-child-width-1-6\@m > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@m > * {
    width: auto; }
  .uk-child-width-expand\@m > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-child-width-1-1\@l > * {
    width: 100%; }
  .uk-child-width-1-2\@l > * {
    width: 50%; }
  .uk-child-width-1-3\@l > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@l > * {
    width: 25%; }
  .uk-child-width-1-5\@l > * {
    width: 20%; }
  .uk-child-width-1-6\@l > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@l > * {
    width: auto; }
  .uk-child-width-expand\@l > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-child-width-1-1\@xl > * {
    width: 100%; }
  .uk-child-width-1-2\@xl > * {
    width: 50%; }
  .uk-child-width-1-3\@xl > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@xl > * {
    width: 25%; }
  .uk-child-width-1-5\@xl > * {
    width: 20%; }
  .uk-child-width-1-6\@xl > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@xl > * {
    width: auto; }
  .uk-child-width-expand\@xl > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Single Widths
 ========================================================================== */
/*
 * 1. `max-width` is needed for the pixel-based classes
 */
[class*='uk-width'] {
  box-sizing: border-box;
  width: 100%;
  /* 1 */
  max-width: 100%; }

/* Halves */
.uk-width-1-2 {
  width: 50%; }

/* Thirds */
.uk-width-1-3 {
  width: calc(100% * 1 / 3.001); }

.uk-width-2-3 {
  width: calc(100% * 2 / 3.001); }

/* Quarters */
.uk-width-1-4 {
  width: 25%; }

.uk-width-3-4 {
  width: 75%; }

/* Fifths */
.uk-width-1-5 {
  width: 20%; }

.uk-width-2-5 {
  width: 40%; }

.uk-width-3-5 {
  width: 60%; }

.uk-width-4-5 {
  width: 80%; }

/* Sixths */
.uk-width-1-6 {
  width: calc(100% * 1 / 6.001); }

.uk-width-5-6 {
  width: calc(100% * 5 / 6.001); }

/* Pixel */
.uk-width-small {
  width: 150px; }

.uk-width-medium {
  width: 300px; }

.uk-width-large {
  width: 450px; }

.uk-width-xlarge {
  width: 600px; }

.uk-width-2xlarge {
  width: 750px; }

/* Auto */
.uk-width-auto {
  width: auto; }

/* Expand */
.uk-width-expand {
  flex: 1;
  min-width: 1px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  /* Whole */
  .uk-width-1-1\@s {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@s {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@s {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@s {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@s {
    width: 25%; }
  .uk-width-3-4\@s {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@s {
    width: 20%; }
  .uk-width-2-5\@s {
    width: 40%; }
  .uk-width-3-5\@s {
    width: 60%; }
  .uk-width-4-5\@s {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@s {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@s {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@s {
    width: 150px; }
  .uk-width-medium\@s {
    width: 300px; }
  .uk-width-large\@s {
    width: 450px; }
  .uk-width-xlarge\@s {
    width: 600px; }
  .uk-width-2xlarge\@s {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@s {
    width: auto; }
  /* Expand */
  .uk-width-expand\@s {
    flex: 1;
    min-width: 1px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-1-1\@m {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@m {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@m {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@m {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@m {
    width: 25%; }
  .uk-width-3-4\@m {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@m {
    width: 20%; }
  .uk-width-2-5\@m {
    width: 40%; }
  .uk-width-3-5\@m {
    width: 60%; }
  .uk-width-4-5\@m {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@m {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@m {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@m {
    width: 150px; }
  .uk-width-medium\@m {
    width: 300px; }
  .uk-width-large\@m {
    width: 450px; }
  .uk-width-xlarge\@m {
    width: 600px; }
  .uk-width-2xlarge\@m {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@m {
    width: auto; }
  /* Expand */
  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Whole */
  .uk-width-1-1\@l {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@l {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@l {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@l {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@l {
    width: 25%; }
  .uk-width-3-4\@l {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@l {
    width: 20%; }
  .uk-width-2-5\@l {
    width: 40%; }
  .uk-width-3-5\@l {
    width: 60%; }
  .uk-width-4-5\@l {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@l {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@l {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@l {
    width: 150px; }
  .uk-width-medium\@l {
    width: 300px; }
  .uk-width-large\@l {
    width: 450px; }
  .uk-width-xlarge\@l {
    width: 600px; }
  .uk-width-2xlarge\@l {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@l {
    width: auto; }
  /* Expand */
  .uk-width-expand\@l {
    flex: 1;
    min-width: 1px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  /* Whole */
  .uk-width-1-1\@xl {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@xl {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@xl {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@xl {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@xl {
    width: 25%; }
  .uk-width-3-4\@xl {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@xl {
    width: 20%; }
  .uk-width-2-5\@xl {
    width: 40%; }
  .uk-width-3-5\@xl {
    width: 60%; }
  .uk-width-4-5\@xl {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@xl {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@xl {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@xl {
    width: 150px; }
  .uk-width-medium\@xl {
    width: 300px; }
  .uk-width-large\@xl {
    width: 450px; }
  .uk-width-xlarge\@xl {
    width: 600px; }
  .uk-width-2xlarge\@xl {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@xl {
    width: auto; }
  /* Expand */
  .uk-width-expand\@xl {
    flex: 1;
    min-width: 1px; } }

/* ========================================================================
   Component: Height
 ========================================================================== */
[class*='uk-height'] {
  box-sizing: border-box; }

/*
 * Only works if parent element has a height set
 */
.uk-height-1-1 {
  height: 100%; }

/*
 * Useful to create image teasers
 */
.uk-height-viewport {
  min-height: 100vh; }

/*
 * Pixel
 * Useful for `overflow: auto`
 */
.uk-height-small {
  height: 150px; }

.uk-height-medium {
  height: 300px; }

.uk-height-large {
  height: 450px; }

.uk-height-max-small {
  max-height: 150px; }

.uk-height-max-medium {
  max-height: 300px; }

.uk-height-max-large {
  max-height: 450px; }

/* ========================================================================
   Component: Transition
 ========================================================================== */
/* Toggle (Hover + Focus)
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-transition-toggle {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/*
 * Remove outline for `tabindex`
 */
.uk-transition-toggle:focus {
  outline: none; }

/* Transitions
 ========================================================================== */
/*
 * The toggle is triggered on touch devices by two methods:
 * 1. Using `:focus` and tabindex
 * 2. Using `:hover` and a `touchstart` event listener registered on the document
 *    (Doesn't work on Surface touch devices)
 *
 * Note: Transitions don't work with `uk-postion-center-*` classes because they also use `transform`,
 *       therefore it's recommended to use an extra `div` for the transition.
 */
.uk-transition-fade,
[class*='uk-transition-scale'],
[class*='uk-transition-slide'] {
  transition: 0.3s ease-out;
  transition-property: opacity, transform, filter;
  opacity: 0; }

/*
 * Fade
 */
.uk-transition-toggle:hover .uk-transition-fade,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-active.uk-active .uk-transition-fade {
  opacity: 1; }

/*
 * Scale
 */
.uk-transition-scale-up {
  transform: scale(1, 1); }

.uk-transition-scale-down {
  transform: scale(1.1, 1.1); }

/* Show */
.uk-transition-toggle:hover .uk-transition-scale-up,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-active.uk-active .uk-transition-scale-up {
  opacity: 1;
  transform: scale(1.1, 1.1); }

.uk-transition-toggle:hover .uk-transition-scale-down,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-active.uk-active .uk-transition-scale-down {
  opacity: 1;
  transform: scale(1, 1); }

/*
 * Slide
 */
.uk-transition-slide-top {
  transform: translateY(-100%); }

.uk-transition-slide-bottom {
  transform: translateY(100%); }

.uk-transition-slide-left {
  transform: translateX(-100%); }

.uk-transition-slide-right {
  transform: translateX(100%); }

.uk-transition-slide-top-small {
  transform: translateY(-10px); }

.uk-transition-slide-bottom-small {
  transform: translateY(10px); }

.uk-transition-slide-left-small {
  transform: translateX(-10px); }

.uk-transition-slide-right-small {
  transform: translateX(10px); }

.uk-transition-slide-top-medium {
  transform: translateY(-50px); }

.uk-transition-slide-bottom-medium {
  transform: translateY(50px); }

.uk-transition-slide-left-medium {
  transform: translateX(-50px); }

.uk-transition-slide-right-medium {
  transform: translateX(50px); }

/* Show */
.uk-transition-toggle:hover [class*='uk-transition-slide'],
.uk-transition-toggle:focus [class*='uk-transition-slide'],
.uk-transition-active.uk-active [class*='uk-transition-slide'] {
  opacity: 1;
  transform: translate(0, 0); }

/* Opacity modifier
 ========================================================================== */
.uk-transition-opaque {
  opacity: 1; }

/* Duration modifiers
 ========================================================================== */
.uk-transition-slow {
  transition-duration: 0.7s; }

/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Panel
 ========================================================================== */
.uk-panel {
  display: flow-root;
  position: relative;
  box-sizing: border-box; }

/*
 * Remove margin from the last-child
 */
.uk-panel > :last-child {
  margin-bottom: 0; }

/*
 * Scrollable
 */
.uk-panel-scrollable {
  height: 170px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  resize: both; }

/* Clearfix
 ========================================================================== */
/*
 * 1. `table-cell` is used with `::before` because `table` creates a 1px gap when it becomes a flex item, only in Webkit
 * 2. `table` is used again with `::after` because `clear` only works with block elements.
 * Note: `display: block` with `overflow: hidden` is currently not working in the latest Safari
 */
/* 1 */
.uk-clearfix::before {
  content: "";
  display: table-cell; }

/* 2 */
.uk-clearfix::after {
  content: "";
  display: table;
  clear: both; }

/* Float
 ========================================================================== */
/*
 * 1. Prevent content overflow
 */
.uk-float-left {
  float: left; }

.uk-float-right {
  float: right; }

/* 1 */
[class*='uk-float-'] {
  max-width: 100%; }

/* Overfow
 ========================================================================== */
.uk-overflow-hidden {
  overflow: hidden; }

/*
 * Enable scrollbars if content is clipped
 * Note: Firefox ignores `padding-bottom` for the scrollable overflow https://bugzilla.mozilla.org/show_bug.cgi?id=748518
 */
.uk-overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.uk-overflow-auto > :last-child {
  margin-bottom: 0; }

/* Resize
 ========================================================================== */
.uk-resize {
  resize: both; }

.uk-resize-vertical {
  resize: vertical; }

/* Display
 ========================================================================== */
.uk-display-block {
  display: block !important; }

.uk-display-inline {
  display: inline !important; }

.uk-display-inline-block {
  display: inline-block !important; }

/* Inline
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 * 5. Force hardware acceleration without creating a new stacking context
 *    to fix 1px glitch when combined with overlays and transitions in Webkit
 * 6. Clip child elements
 */
[class*='uk-inline'] {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  -webkit-backface-visibility: hidden; }

.uk-inline-clip {
  /* 6 */
  overflow: hidden; }

/* Responsive objects
 ========================================================================== */
/*
 * Preserve original dimensions
 * Because `img, `video`, `canvas` and  `audio` are already responsive by default, see Base component
 */
.uk-preserve-width,
.uk-preserve-width canvas,
.uk-preserve-width img,
.uk-preserve-width svg,
.uk-preserve-width video {
  max-width: none; }

/*
 * Responsiveness
 * Corrects `max-width` and `max-height` behavior if padding and border are used
 */
.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box; }

/*
 * 1. Set a maximum width. `important` needed to override `uk-preserve-width img`
 * 2. Auto scale the height. Only needed if `height` attribute is present
 */
.uk-responsive-width {
  /* 1 */
  max-width: 100% !important;
  /* 2 */
  height: auto; }

/*
 * 1. Set a maximum height. Only works if the parent element has a fixed height
 * 2. Auto scale the width. Only needed if `width` attribute is present
 * 3. Reset max-width, which `img, `video`, `canvas` and  `audio` already have by default
 */
.uk-responsive-height {
  /* 1 */
  max-height: 100%;
  /* 2 */
  width: auto;
  /* 3 */
  max-width: none; }

/* Border
 ========================================================================== */
.uk-border-circle {
  border-radius: 50%; }

.uk-border-pill {
  border-radius: 500px; }

.uk-border-rounded {
  border-radius: 5px; }

/*
 * Fix `overflow: hidden` to be ignored with border-radius and CSS transforms in Webkit
 */
.uk-inline-clip[class*='uk-border-'] {
  -webkit-transform: translateZ(0); }

/* Box-shadow
 ========================================================================== */
.uk-box-shadow-small {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-medium {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-large {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16); }

.uk-box-shadow-xlarge {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16); }

/*
 * Hover
 */
[class*='uk-box-shadow-hover'] {
  transition: box-shadow 0.1s ease-in-out; }

.uk-box-shadow-hover-small:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-hover-medium:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-hover-large:hover {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16); }

.uk-box-shadow-hover-xlarge:hover {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16); }

/* Box-shadow bottom
 ========================================================================== */
/*
 * 1. Set position.
 * 2. Set style
 * 3. Blur doesn't work on pseudo elements with negative `z-index` in Edge.
 *    Solved by using `before` and add position context to child elements.
 */
@supports (filter: blur(0)) {
  .uk-box-shadow-bottom {
    display: inline-block;
    position: relative;
    max-width: 100%;
    vertical-align: middle; }
  .uk-box-shadow-bottom::before {
    content: '';
    /* 1 */
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    /* 2 */
    height: 30px;
    border-radius: 100%;
    background: #444;
    filter: blur(20px); }
  /* 3 */
  .uk-box-shadow-bottom > * {
    position: relative; } }

/* Drop cap
 ========================================================================== */
/*
 * 1. Firefox doesn't apply `::first-letter` if the first letter is inside child elements
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=214004
 * 2. In Firefox, a floating `::first-letter` doesn't have a line box and there for no `line-height`
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=317933
 * 3. Caused by 1.: Edge creates two nested `::first-letter` containers, one for each selector
 *    This doubles the `font-size` exponential when using the `em` unit.
 */
.uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
  display: block;
  margin-right: 10px;
  float: left;
  font-size: 4.5em;
  line-height: 1; }

/* 2 */
@-moz-document url-prefix() {
  .uk-dropcap::first-letter,
  .uk-dropcap > p:first-of-type::first-letter {
    margin-top: 1.1%; } }

/* 3 */
@supports (-ms-ime-align: auto) {
  .uk-dropcap > p:first-of-type::first-letter {
    font-size: 1em; } }

/* Logo
 ========================================================================== */
/*
 * 1. Required for `a`
 */
.uk-logo {
  font-size: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #666;
  /* 1 */
  text-decoration: none; }

/* Hover + Focus */
.uk-logo:hover,
.uk-logo:focus {
  color: #666;
  outline: none;
  /* 1 */
  text-decoration: none; }

.uk-logo-inverse {
  display: none; }

/* Disabled State
 ========================================================================== */
.uk-disabled {
  pointer-events: none; }

/* Drag State
 ========================================================================== */
/*
 * 1. Needed if moving over elements with have their own cursor on hover, e.g. links or buttons
 * 2. Fix dragging over iframes
 */
.uk-drag,
.uk-drag * {
  cursor: move; }

/* 2 */
.uk-drag iframe {
  pointer-events: none; }

/* Dragover State
 ========================================================================== */
/*
 * Create a box-shadow when dragging a file over the upload area
 */
.uk-dragover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.3); }

/* Blend modes
 ========================================================================== */
.uk-blend-multiply {
  mix-blend-mode: multiply; }

.uk-blend-screen {
  mix-blend-mode: screen; }

.uk-blend-overlay {
  mix-blend-mode: overlay; }

.uk-blend-darken {
  mix-blend-mode: darken; }

.uk-blend-lighten {
  mix-blend-mode: lighten; }

.uk-blend-color-dodge {
  mix-blend-mode: color-dodge; }

.uk-blend-color-burn {
  mix-blend-mode: color-burn; }

.uk-blend-hard-light {
  mix-blend-mode: hard-light; }

.uk-blend-soft-light {
  mix-blend-mode: soft-light; }

.uk-blend-difference {
  mix-blend-mode: difference; }

.uk-blend-exclusion {
  mix-blend-mode: exclusion; }

.uk-blend-hue {
  mix-blend-mode: hue; }

.uk-blend-saturation {
  mix-blend-mode: saturation; }

.uk-blend-color {
  mix-blend-mode: color; }

.uk-blend-luminosity {
  mix-blend-mode: luminosity; }

/* Transform
========================================================================== */
.uk-transform-center {
  transform: translate(-50%, -50%); }

/* Transform Origin
========================================================================== */
.uk-transform-origin-top-left {
  transform-origin: 0 0; }

.uk-transform-origin-top-center {
  transform-origin: 50% 0; }

.uk-transform-origin-top-right {
  transform-origin: 100% 0; }

.uk-transform-origin-center-left {
  transform-origin: 0 50%; }

.uk-transform-origin-center-right {
  transform-origin: 100% 50%; }

.uk-transform-origin-bottom-left {
  transform-origin: 0 100%; }

.uk-transform-origin-bottom-center {
  transform-origin: 50% 100%; }

.uk-transform-origin-bottom-right {
  transform-origin: 100% 100%; }

/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex; }

.uk-flex-inline {
  display: inline-flex; }

/*
 * Remove pseudo elements created by micro clearfix as precaution
 */
.uk-flex::before,
.uk-flex::after,
.uk-flex-inline::before,
.uk-flex-inline::after {
  display: none; }

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start; }

.uk-flex-center {
  justify-content: center; }

.uk-flex-right {
  justify-content: flex-end; }

.uk-flex-between {
  justify-content: space-between; }

.uk-flex-around {
  justify-content: space-around; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start; }
  .uk-flex-center\@s {
    justify-content: center; }
  .uk-flex-right\@s {
    justify-content: flex-end; }
  .uk-flex-between\@s {
    justify-content: space-between; }
  .uk-flex-around\@s {
    justify-content: space-around; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-left\@m {
    justify-content: flex-start; }
  .uk-flex-center\@m {
    justify-content: center; }
  .uk-flex-right\@m {
    justify-content: flex-end; }
  .uk-flex-between\@m {
    justify-content: space-between; }
  .uk-flex-around\@m {
    justify-content: space-around; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@l {
    justify-content: flex-start; }
  .uk-flex-center\@l {
    justify-content: center; }
  .uk-flex-right\@l {
    justify-content: flex-end; }
  .uk-flex-between\@l {
    justify-content: space-between; }
  .uk-flex-around\@l {
    justify-content: space-around; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-left\@xl {
    justify-content: flex-start; }
  .uk-flex-center\@xl {
    justify-content: center; }
  .uk-flex-right\@xl {
    justify-content: flex-end; }
  .uk-flex-between\@xl {
    justify-content: space-between; }
  .uk-flex-around\@xl {
    justify-content: space-around; } }

/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch; }

.uk-flex-top {
  align-items: flex-start; }

.uk-flex-middle {
  align-items: center; }

.uk-flex-bottom {
  align-items: flex-end; }

/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row; }

.uk-flex-row-reverse {
  flex-direction: row-reverse; }

.uk-flex-column {
  flex-direction: column; }

.uk-flex-column-reverse {
  flex-direction: column-reverse; }

/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap; }

.uk-flex-wrap {
  flex-wrap: wrap; }

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch; }

.uk-flex-wrap-top {
  align-content: flex-start; }

.uk-flex-wrap-middle {
  align-content: center; }

.uk-flex-wrap-bottom {
  align-content: flex-end; }

.uk-flex-wrap-between {
  align-content: space-between; }

.uk-flex-wrap-around {
  align-content: space-around; }

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1; }

.uk-flex-last {
  order: 99; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1; }
  .uk-flex-last\@s {
    order: 99; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-first\@m {
    order: -1; }
  .uk-flex-last\@m {
    order: 99; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@l {
    order: -1; }
  .uk-flex-last\@l {
    order: 99; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-first\@xl {
    order: -1; }
  .uk-flex-last\@xl {
    order: 99; } }

/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none; }

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto; }

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1; }

/*
 * Plugin: Web Slide Navigation System
 * Demo Link: http://webslidemenu.uxwing.com/
 * Author: UXWing
 * License: http://codecanyon.net/licenses/standard
*/
/*--------------------------------------------------------------------------
[Table of contents]

01 Base CSS
02 Desktop CSS
03 Tab CSS
04 Mobile CSS
05 Additional Themes (Optionnal)
--------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------
|  01  Base CSS
--------------------------------------------------------------------------*/
.wsmain html, .wsmain body, .wsmain iframe, .wsmain h1, .wsmain h2, .wsmain h3, .wsmain h4, .wsmain h5, .wsmain h6 {
  margin: 0px;
  padding: 0px;
  border: 0px;
  font: inherit;
  vertical-align: baseline;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  font-smooth: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased !important;
  font-smoothing: subpixel-antialiased !important;
  font-smooth: subpixel-antialiased; }

.wsmenu .cl {
  clear: both; }

.wsmenu img, object, embed, video {
  border: 0 none;
  max-width: 100%; }

.wsmenu a:focus {
  outline: none !important; }

.wsmenu:before, .wsmenu:after {
  content: "";
  display: table; }

.wsmenu:after {
  clear: both; }

/*--------------------------------------------------------------------------
|  02 Desktop CSS
--------------------------------------------------------------------------*/
.wsmobileheader {
  display: none; }

#overlapblackbg {
  display: none; }

.wsmenu {
  color: #fff;
  position: relative;
  padding: 0px;
  -webkit-border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px; }

.wsmenu-list {
  text-align: left;
  margin: 0 auto 0 auto;
  width: 100%;
  padding: 0px; }

.wsmenu-list > li {
  text-align: center;
  display: table-cell; }

.wsmenu-list li:first-child a {
  -webkit-border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px; }

.wsmenu-list li:last-child a {
  -webkit-border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
  border-right: 0px solid; }

.wsmenu-list > li > a .fa {
  display: inline-block;
  font-size: 14px;
  line-height: inherit;
  margin-right: 4px; }

.wsmenu-list li ul li a .fa.fa-angle-double-right {
  font-size: 12px;
  margin: 0 3px 0 -4px; }

.wsmenu-list li a .arrow:after {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-top: 4px solid #b3b3b3;
  content: "";
  float: right;
  height: 0;
  margin: 0 0 0 9px;
  position: absolute;
  text-align: right;
  top: 22px;
  width: 0; }

.wsmenu-list > li > a {
  display: block;
  text-decoration: none;
  position: relative; }

.wsmenu-list li a:hover .arrow:after {
  border-top-color: #b3b3b3; }

.wsmenu-list li a.active .arrow:after {
  border-top-color: #b3b3b3; }

.wsmenu-list li:hover > a .arrow:after {
  border-top-color: #b3b3b3; }

.megamenu iframe {
  width: 100%;
  margin-top: 10px;
  min-height: 200px; }

.megamenu video {
  width: 100%;
  margin-top: 10px;
  min-height: 200px; }

.wsmenu-list li:hover .megamenu {
  opacity: 1; }

.megamenu {
  width: 100%;
  left: 0px;
  position: absolute;
  top: 48px;
  color: #000;
  z-index: 1000;
  margin: 0px;
  text-align: left;
  padding: 14px;
  font-size: 15px;
  border: solid 1px #eeeeee;
  background-color: #fff;
  opacity: 0;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s; }

.megamenu .title {
  border-bottom: 1px solid #CCC;
  font-size: 14px;
  padding: 9px 5px 9px 0px;
  font-size: 17px;
  color: #424242;
  margin-bottom: 7px;
  text-align: left;
  height: 39px;
  margin: 0px 0px 10px 0px; }

.link-list li {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-align: left; }

.link-list li a {
  line-height: 18px;
  border-right: none;
  text-align: left;
  background-image: none !important;
  border-right: 0 none !important;
  display: block; }

.link-list .fa {
  font-size: 11px; }

.megacollink {
  width: 23%;
  float: left;
  margin: 0% 1%;
  padding: 0px; }

.megacollink li {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-align: left; }

.megacollink li a {
  line-height: 18px;
  border-right: none;
  text-align: left;
  padding: 8px 0px;
  background: #fff !important;
  background-image: none !important;
  color: #666666 !important;
  border-right: 0 none !important;
  display: block;
  border-right: 1px solid #e7e7e7;
  background-color: #fff;
  color: #424242; }

.megacollink .fa {
  font-size: 11px; }

.megacolimage {
  width: 31.33%;
  float: left;
  margin: 0% 1%; }

.typographydiv {
  width: 100%;
  margin: 0% 0%; }

.typographylinks {
  width: 25%;
  float: left;
  margin: 0% 0%; }

.typographylinks ul {
  padding: 0px; }

.mainmapdiv {
  width: 100%;
  display: block;
  margin: 0% 0%; }

.wsmenu-list .ad-style {
  width: 28%;
  float: right; }

.wsmenu-list .ad-style a {
  border: none !important;
  padding: 0px !important;
  margin: 0px !important;
  line-height: normal !important;
  background-image: none !important; }

.mobile-sub .megamenu .ad-style a:hover {
  background-color: transparent !important; }

.wsmenu-list .megamenu li:hover > a {
  background: transparent !important; }

.wsmenu-list .megamenu li a:hover {
  background: transparent !important;
  text-decoration: underline; }

.wsmenu-list .megamenu li .fa {
  margin-right: 5px;
  text-align: center;
  width: 18px; }

.mrgtop {
  margin-top: 15px; }

.show-grid div {
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #dbdbdb;
  border: 1px solid #e7e7e7;
  color: #6a6a6a;
  margin: 2px 0px; }

.halfmenu {
  width: 40%;
  right: auto !important;
  left: auto !important; }

.halfmenu .megacollink {
  width: 48%;
  float: left;
  margin: 0% 1%; }

.halfdiv {
  width: 35%;
  right: 0px !important;
  left: auto; }

.menu_form {
  width: 100%;
  display: block; }

.menu_form input[type="text"] {
  width: 100%;
  border: 1px solid #e2e2e2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #000;
  font-size: 13px;
  padding: 8px 5px;
  margin-bottom: 8px; }

.menu_form textarea {
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  color: #000;
  font-size: 13px;
  padding: 8px 5px;
  margin-bottom: 8px;
  min-height: 122px; }

.menu_form input[type="submit"] {
  width: 25%;
  display: block;
  height: 28px;
  float: right;
  border: solid 1px #ccc;
  margin-right: 15px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.menu_form input[type="button"] {
  width: 25%;
  display: block;
  height: 28px;
  float: right;
  border: solid 1px #ccc;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.megamenu .left.carousel-control {
  padding-top: 20%; }

.megamenu .right.carousel-control {
  padding-top: 20%; }

.carousel-inner .item img {
  width: 100%; }

.megamenu .carousel-caption {
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  height: 31px;
  left: 0;
  padding: 7px 0;
  right: 0;
  width: 100%; }

.wsmenu-list li > .wsmenu-submenu {
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  visibility: hidden; }

.wsmenu-list li:hover > .wsmenu-submenu {
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  opacity: 1;
  visibility: visible; }

.wsmenu-submenu li > .wsmenu-submenu-sub {
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  visibility: hidden; }

.wsmenu-submenu li:hover > .wsmenu-submenu-sub {
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  opacity: 1;
  visibility: visible; }

.wsmenu-submenu-sub li > .wsmenu-submenu-sub-sub {
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  visibility: hidden; }

.wsmenu-submenu-sub li:hover > .wsmenu-submenu-sub-sub {
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  opacity: 1;
  visibility: visible; }

.wsmenu-list li > .megamenu {
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  visibility: hidden; }

.wsmenu-list li:hover > .megamenu {
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  opacity: 1;
  visibility: visible; }

.wsmenu-submenu {
  position: absolute;
  top: 48px;
  z-index: 1000;
  margin: 0px;
  padding: 2px;
  border: solid 1px #eeeeee;
  background-color: #fff;
  opacity: 0;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  min-width: 165px; }

.wsmenu-submenu li a {
  background: #fff !important;
  background-image: none !important;
  color: #666666 !important;
  border-right: 0 none !important;
  text-align: left;
  display: block;
  line-height: 22px;
  padding: 6px 12px;
  text-transform: none;
  font-size: 13px;
  letter-spacing: normal;
  border-right: 0px solid; }

.wsmenu-submenu li {
  position: relative;
  margin: 0px;
  padding: 0px; }

.wsmenuexpandermain {
  display: none; }

.wsmenu-list li:hover .wsmenu-submenu {
  display: block; }

.dropleft {
  left: auto !important;
  right: 100% !important; }

.dropleftright {
  left: auto !important;
  right: 100% !important; }

.wsmenu-list .wsmenu-submenu .wsmenu-submenu-sub {
  min-width: 220px;
  position: absolute;
  left: 100%;
  top: 0;
  margin: 0px;
  padding: 0px;
  min-width: 165px;
  opacity: 0;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.4s, opacity 0.4s;
  -ms-transition: -ms-transform 0.4s, opacity 0.4s;
  -moz-transition: -moz-transform 0.4s, opacity 0.4s;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s; }

.wsmenu-list .wsmenu-submenu li:hover .wsmenu-submenu-sub {
  opacity: 1;
  list-style: none;
  padding: 2px;
  border: solid 1px #eeeeee;
  background-color: #fff; }

.wsmenu-list .wsmenu-submenu li:hover .wsmenu-submenu-sub {
  display: block; }

.wsmenu-list .wsmenu-submenu .wsmenu-submenu-sub .wsmenu-submenu-sub-sub {
  min-width: 165px;
  position: absolute;
  left: 100%;
  top: 0;
  margin: 0px;
  padding: 0px;
  opacity: 0;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.4s, opacity 0.4s;
  -ms-transition: -ms-transform 0.4s, opacity 0.4s;
  -moz-transition: -moz-transform 0.4s, opacity 0.4s;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s; }

.wsmenu-list .wsmenu-submenu .wsmenu-submenu-sub li:hover .wsmenu-submenu-sub-sub {
  opacity: 1;
  list-style: none;
  padding: 2px;
  border: solid 1px #eeeeee;
  background-color: #fff; }

.wsmenu-submenu li {
  position: relative;
  padding: 0px;
  margin: 0px;
  display: block; }

.wsmenu-click {
  display: none; }

.wsmenu-click02 {
  display: none; }

.wsmenu-list li:hover > a {
  text-decoration: none; }

.wsmenu-list > li > a:hover {
  text-decoration: none; }

.wsmenu-list > li > a.active {
  background-color: rgba(0, 0, 0, 0.08) !important;
  text-decoration: none; }

.wsmenu-submenu > li > a:hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
  border-radius: 0px !important;
  text-decoration: none;
  text-decoration: none; }

.typography-text {
  padding: 0px 0px;
  font-size: 15px; }

.typography-text ul {
  padding: 0px 0px;
  margin: 0px; }

.typography-text p {
  text-align: justify;
  line-height: 24px;
  color: #656565; }

.typography-text ul li {
  display: block;
  padding: 2px 0px;
  line-height: 22px; }

.typography-text ul li a {
  color: #656565; }

.hometext {
  display: none; }

.wsmenu-submenu .fa {
  margin-right: 7px; }

/*--------------------------------------------------------------------------
|  03 Tab CSS
--------------------------------------------------------------------------*/
@media only screen and (min-width: 781px) and (max-width: 1064px) {
  .wsmenu-list > li > a > .fa {
    display: none !important; }
  .hometext {
    display: block !important; }
  .wsmenu {
    width: 100%;
    font-size: 13px !important; }
  .wsmenu-list li a {
    white-space: nowrap !important;
    padding-left: 4px !important;
    padding-right: 10px !important; }
  .megacollink {
    width: 48%;
    margin: 1% 1%; }
  .typographylinks {
    width: 48%;
    margin: 1% 1%; } }

@media only screen and (min-width: 781px) {
  .wsmenu-list li:hover > .wsmenu-submenu {
    display: block !important; }
  .wsmenu-submenu li:hover > .wsmenu-submenu-sub {
    display: block !important; }
  .wsmenu-submenu-sub li:hover > .wsmenu-submenu-sub-sub {
    display: block !important; }
  .wsmenu-list li:hover > .megamenu {
    display: block !important; }
  .wsmenu-list > .menu-arrow-1 > a:after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #c1c1c1 transparent transparent transparent;
    border-image: none;
    border-style: solid;
    border-width: 4px;
    top: 24px;
    content: "";
    margin-top: 0;
    position: absolute;
    right: 7px;
    z-index: 11111; }
  .wsmenu-list > li > .wsmenu-submenu > .menu-arrow-2 > a:after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: transparent transparent transparent #c1c1c1;
    border-image: none;
    border-style: solid;
    border-width: 4px;
    top: 14px;
    content: "";
    margin-top: 0;
    position: absolute;
    right: 10px;
    z-index: 11111; }
  .wsmenu-list > li > .arrowleft > .menu-arrow-2 > a:after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: transparent #c1c1c1 transparent transparent;
    border-image: none;
    border-style: solid;
    border-width: 4px;
    top: 14px;
    content: "";
    margin-top: 0;
    position: absolute;
    right: auto !important;
    left: 10px !important;
    z-index: 11111;
    float: left; }
  .arrowleft li a {
    text-align: center; }
  .wsmenu-list > li > .wsmenu-submenu li > .wsmenu-submenu-sub > .menu-arrow-2 > a:after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: transparent transparent transparent #c1c1c1;
    border-image: none;
    border-style: solid;
    border-width: 4px;
    top: 14px;
    content: "";
    margin-top: 0;
    position: absolute;
    right: 8px;
    z-index: 11111; }
  .wsmenu-list > li > .arrowleft li > .wsmenu-submenu-sub > .menu-arrow-2 > a:after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: transparent #c1c1c1 transparent transparent;
    border-image: none;
    border-style: solid;
    border-width: 4px;
    top: 13px;
    content: "";
    margin-top: 0;
    position: absolute;
    right: auto !important;
    left: 5px !important;
    z-index: 11111; }
  .wsmenu-list > li > .arrowleftright > .menu-arrow-2 > a:after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: transparent #c1c1c1 transparent transparent;
    border-image: none;
    border-style: solid;
    border-width: 4px;
    top: 14px;
    content: "";
    margin-top: 0;
    position: absolute;
    right: auto !important;
    left: 10px !important;
    z-index: 11111;
    float: left; }
  .arrowleftright li a {
    text-align: center; } }

/*--------------------------------------------------------------------------
|  04 Mobile CSS
--------------------------------------------------------------------------*/
@media only screen and (max-width: 780px) {
  .wsoffcanvasopener .wsmenu {
    left: 0px;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  .wsmenucontainer.wsoffcanvasopener {
    display: block; }
  .wsmobileheader {
    display: block !important;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  .wsmenu-list > li:hover > a {
    background-color: rgba(0, 0, 0, 0.08) !important;
    text-decoration: none; }
  .hometext {
    display: inline-block !important; }
  .megacollink {
    width: 96% !important;
    margin: 0% 2% !important; }
  .megacolimage {
    width: 90% !important;
    margin: 0% 5% !important; }
  .typographylinks {
    width: 98% !important;
    margin: 0% 1% !important; }
  .typographydiv {
    width: 86% !important;
    margin: 0% 7% !important; }
  .mainmapdiv {
    width: 90% !important;
    margin: 0% 5% !important; }
  #overlapblackbg {
    left: 0;
    z-index: 102;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.45);
    cursor: pointer; }
  .wsoffcanvasopener #overlapblackbg {
    display: block !important; }
  .wsmenu-submenu > li:hover > a {
    background-color: #7b7b7b;
    color: #666666; }
  .wsmenu > .wsmenu-list > li > a.active {
    color: #666666;
    background-color: rgba(0, 0, 0, 0.08); }
  .wsmenu > .wsmenu-list > li > a:hover {
    color: #666666;
    background-color: rgba(0, 0, 0, 0.08); }
  .wsmenu-list li:hover .wsmenu-submenu {
    display: none; }
  .wsmenu-list li:hover .wsmenu-submenu .wsmenu-submenu-sub {
    display: none; }
  .wsmenu-list .wsmenu-submenu .wsmenu-submenu-sub li:hover .wsmenu-submenu-sub-sub {
    display: none;
    list-style: none;
    padding: 2px;
    border: solid 1px #eeeeee;
    background-color: #fff; }
  .wsmenu-list li:first-child a {
    -webkit-border-radius: 0px 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px; }
  .wsmenu-list li:last-child a {
    -webkit-border-radius: 0px 4px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
    border-right: 0px solid; }
  .innerpnd {
    padding: 0px !important; }
  .typography-text {
    padding: 10px 0px; }
  .wsmenucontainer {
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  .wsmenu-click {
    height: 43px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    width: 100%; }
  .wsmenu-click i {
    display: block;
    height: 23px;
    width: 25px;
    margin-top: 11px;
    margin-right: 8px;
    background-size: 25px;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.25);
    float: right; }
  .wsmenu-click02 {
    height: 43px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    width: 100%; }
  .wsmenu-click02 i {
    display: block;
    height: 23px;
    width: 25px;
    margin-top: 11px;
    margin-right: 8px;
    background-size: 25px;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.25);
    float: right; }
  .ws-activearrow > i {
    transform: rotate(180deg); }
  .wsmenu-rotate {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  .wsmenu-submenu-sub {
    width: 100% !important;
    position: static !important;
    left: 100% !important;
    top: 0 !important;
    display: none;
    margin: 0px !important;
    padding: 0px !important;
    border: solid 0px !important;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important; }
  .wsmenu-submenu-sub li {
    margin: 0px 0px 0px 0px !important;
    padding: 0px;
    position: relative; }
  .wsmenu-submenu-sub a {
    display: block;
    padding: 10px 25px 10px 25px;
    border-bottom: solid 1px #ccc;
    font-weight: normal; }
  .wsmenu-submenu-sub li a.active {
    color: #000 !important; }
  .wsmenu-submenu-sub li:hover > a {
    background-color: #333333 !important;
    color: #fff; }
  .wsmenu-list > li > a .fa {
    margin-right: 6px; }
  .wsmenu-submenu-sub-sub {
    width: 100% !important;
    position: static !important;
    left: 100% !important;
    top: 0 !important;
    display: none;
    margin: 0px !important;
    padding: 0px !important;
    border: solid 0px !important;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important; }
  .wsmenu-submenu-sub-sub li {
    margin: 0px 0px 0px 0px !important; }
  .wsmenu-submenu-sub-sub a {
    display: block;
    color: #000;
    padding: 10px 25px;
    background: #000 !important;
    border-bottom: solid 1px #ccc;
    font-weight: normal; }
  .wsmenu-submenu-sub-sub li a.active {
    color: #000 !important; }
  .wsmenu-submenu-sub-sub li:hover > a {
    background-color: #606060 !important;
    color: #fff; }
  .wsmenu {
    position: relative; }
  .wsmenu .wsmenu-list {
    height: 100%;
    overflow-y: auto;
    display: block !important; }
  .wsmenu .wsmenu-list > li {
    width: 100%;
    display: block;
    float: none;
    border-right: none;
    background-color: transparent;
    position: relative;
    white-space: inherit; }
  .wsmenu > .wsmenu-list > li > a {
    padding: 9px 32px 9px 17px;
    font-size: 14px;
    text-align: left;
    border-right: solid 0px;
    background-color: transparent;
    color: #666666;
    line-height: 25px;
    border-bottom: 1px solid;
    border-bottom-color: rgba(0, 0, 0, 0.13);
    position: static; }
  .wsmenu > .wsmenu-list > li > a > .fa {
    font-size: 16px;
    color: #bfbfbf; }
  .wsmenu .wsmenu-list li a .arrow:after {
    display: none !important; }
  .wsmenu .wsmenu-list li ul li a .fa.fa-caret-right {
    font-size: 12px !important;
    color: #8E8E8E; }
  .mobile-sub .wsmenu-submenu {
    transform: none !important;
    opacity: 1 !important;
    display: none;
    position: relative !important;
    top: 0px;
    background-color: #fff;
    border: solid 1px #ccc;
    padding: 0px;
    visibility: visible !important; }
  .mobile-sub .wsmenu-submenu li a {
    line-height: 20px;
    height: 36px;
    background-color: #e7e7e7 !important;
    font-size: 13px !important;
    padding: 8px 0px 8px 18px;
    color: #8E8E8E; }
  .mobile-sub .wsmenu-submenu li a:hover {
    background-color: #e7e7e7 !important;
    color: #666666;
    text-decoration: underline; }
  .mobile-sub .wsmenu-submenu li:hover > a {
    background-color: #e7e7e7 !important;
    color: #666666; }
  .mobile-sub .wsmenu-submenu li .wsmenu-submenu-sub li a {
    line-height: 20px;
    height: 36px;
    background-color: #e7e7e7 !important;
    border-bottom: none;
    padding-left: 28px; }
  .mobile-sub .wsmenu-submenu li .wsmenu-submenu-sub li .wsmenu-submenu-sub-sub li a {
    line-height: 20px;
    height: 36px;
    background-color: #e7e7e7 !important;
    border-bottom: none !important;
    padding-left: 38px;
    color: #8e8e8e; }
  .wsmenu-list .megamenu {
    background-color: #e7e7e7;
    color: #666666;
    display: none;
    position: relative !important;
    top: 0px;
    padding: 0px;
    border: solid 0px;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important; }
  .wsmenu-list li:hover .megamenu {
    display: none;
    position: relative !important;
    top: 0px; }
  .megamenu .title {
    color: #666666;
    font-size: 15px !important;
    padding: 10px 8px 10px 0px; }
  .halfdiv .title {
    padding-left: 15px; }
  .megamenu > ul {
    width: 100% !important;
    margin: 0px;
    padding: 0px;
    font-size: 13px !important; }
  .megamenu > ul > li > a {
    padding: 9px 14px !important;
    line-height: normal !important;
    font-size: 13px !important;
    background-color: #e7e7e7 !important;
    color: #666666; }
  .megamenu > ul > li > a:hover {
    background-color: #000000 !important; }
  .ad-style {
    width: 100% !important; }
  .megamenu ul li.title {
    line-height: 26px;
    color: #666666;
    margin: 0px;
    font-size: 15px;
    padding: 7px 13px !important;
    border-bottom: 1px solid #ccc;
    background-color: transparent !important; }
  .halfdiv {
    width: 100%;
    display: block; }
  .halfmenu {
    width: 100%;
    display: block; }
  .menu_form {
    padding: 10px 10px 63px 10px;
    background-color: #e7e7e7; }
  .menu_form input[type="button"] {
    width: 46%; }
  .menu_form input[type="submit"] {
    width: 46%; }
  .menu_form textarea {
    min-height: 100px; }
  .wsmenu {
    width: 240px;
    left: -240px;
    height: 100%;
    position: fixed;
    top: 0;
    margin: 0;
    background-color: #fff;
    border-radius: 0px;
    z-index: 103;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border: none !important;
    background-color: #fff !important; }
  .animated-arrow {
    left: 0;
    top: 0;
    z-index: 102;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  .callusicon {
    color: #c9c9c9;
    font-size: 25px;
    height: 25px;
    position: fixed;
    right: 15px;
    top: 13px;
    transition: all 0.4s ease-in-out 0s;
    width: 25px;
    z-index: 102;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  .callusicon:focus, callusicon:hover {
    color: #fff !important; }
  .wsoffcanvasopener .callusicon {
    display: none !important; }
  .smallogo {
    width: 100%;
    display: block;
    text-align: center;
    padding-top: 9px;
    position: fixed;
    z-index: 101;
    width: 100%;
    height: 52px;
    background: #262626;
    background: -moz-linear-gradient(top, #262626 0, #1c1c1c 50%, #262626 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #262626), color-stop(50%, #1c1c1c), color-stop(100%, #262626));
    background: -webkit-linear-gradient(top, #262626 0, #1c1c1c 50%, #262626 100%);
    background: -o-linear-gradient(top, #262626 0, #1c1c1c 50%, #262626 100%);
    background: -ms-linear-gradient(top, #262626 0, #1c1c1c 50%, #262626 100%);
    background: linear-gradient(to bottom, #262626 0, #1c1c1c 50%, #262626 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#262626', endColorstr='#262626', GradientType=0);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5); }
  .animated-arrow {
    cursor: pointer;
    padding: 13px 35px 16px 0px;
    margin: 10px 0 0 15px; }
  .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
    cursor: pointer;
    height: 3px;
    width: 23px;
    background: #c9c9c9;
    position: absolute;
    display: block;
    content: ''; }
  .animated-arrow span:before {
    top: -7px; }
  .animated-arrow span:after {
    bottom: -7px; }
  .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
    transition: all 500ms ease-in-out; }
  .wsoffcanvasopener .animated-arrow span {
    background-color: transparent !important; }
  .wsoffcanvasopener .animated-arrow span:before, .animated-arrow.active span:after {
    top: 0px; }
  .wsoffcanvasopener .animated-arrow span:before {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 0px; }
  .wsoffcanvasopener .animated-arrow span:after {
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    bottom: 0; }
  .wsmenu-list li > .wsmenu-submenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important; }
  .wsmenu-list li:hover > .wsmenu-submenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important; }
  .wsmenu-submenu li > .wsmenu-submenu-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important; }
  .wsmenu-submenu li:hover > .wsmenu-submenu-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important; }
  .wsmenu-submenu-sub li > .wsmenu-submenu-sub-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important; }
  .wsmenu-submenu-sub li:hover > .wsmenu-submenu-sub-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important; }
  .wsmenu-list li > .megamenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important; }
  .wsmenu-list li:hover > .megamenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important; } }

/*--------------------------------------------------------------------------
|  05 Additional Themes (Optionnal)
--------------------------------------------------------------------------*/
.gry {
  background: #424242;
  color: #fff; }

.orange {
  background: #ff670f;
  color: #fff; }

.blue {
  background: #4A89DC;
  color: #fff; }

.green {
  background: #74a52e;
  color: #fff; }

.red {
  background: #DA4B38;
  color: #fff; }

.yellow {
  background: #F6BB42;
  color: #fff; }

.purple {
  background: #967ADC;
  color: #fff; }

.pink {
  background: #ee1289;
  color: #fff; }

.chocolate {
  background: #934915;
  color: #fff; }

.gry > .wsmenu-list > li > a {
  color: #424242;
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

.gry > .wsmenu-list > li > a {
  background-color: #fff !important; }

.gry > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #E5E5E5 !important; }

.gry > .wsmenu-list > li:hover > a {
  background: #424242 !important;
  color: #fff !important; }

.gry > .wsmenu-list > li > a:hover {
  background: #424242 !important;
  color: #fff !important; }

.gry > .wsmenu-list > li > a.active {
  background: #424242 !important;
  color: #fff !important; }

.gry > .wsmenu-submenu > li > a:hover {
  background: #424242;
  border-radius: 0px !important;
  color: #fff; }

.gry .wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff; }

.gry .wsmenu-list li a.active .arrow:after {
  border-top-color: #fff; }

.gry .wsmenu-list li:hover > a .arrow:after {
  border-top-color: #fff; }

.orange > .wsmenu-list > li > a {
  color: #424242;
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

.orange > .wsmenu-list > li > a {
  background-color: #fff !important; }

.orange > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #E5E5E5 !important; }

.orange > .wsmenu-list > li:hover > a {
  background: #ff670f !important;
  color: #fff !important; }

.orange > .wsmenu-list > li > a:hover {
  background: #ff670f !important;
  color: #fff !important; }

.orange > .wsmenu-list > li > a.active {
  background: #ff670f !important;
  color: #fff !important; }

.orange > .wsmenu-submenu > li > a:hover {
  background: #ff670f;
  border-radius: 0px !important;
  color: #fff; }

.orange .wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff; }

.orange .wsmenu-list li a.active .arrow:after {
  border-top-color: #fff; }

.orange .wsmenu-list li:hover > a .arrow:after {
  border-top-color: #fff; }

.blue > .wsmenu-list > li > a {
  color: #424242;
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

.blue > .wsmenu-list > li > a {
  background-color: #fff !important; }

.blue > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #E5E5E5 !important; }

.blue > .wsmenu-list > li:hover > a {
  background: #4A89DC !important;
  color: #fff !important; }

.blue > .wsmenu-list > li > a:hover {
  background: #4A89DC !important;
  color: #fff !important; }

.blue > .wsmenu-list > li > a.active {
  background: #4A89DC !important;
  color: #fff !important; }

.blue > .wsmenu-submenu li > a:hover {
  background: #4A89DC !important;
  border-radius: 0px !important;
  color: #fff !important; }

.blue .wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff; }

.blue .wsmenu-list li a.active .arrow:after {
  border-top-color: #fff; }

.blue .wsmenu-list li:hover > a .arrow:after {
  border-top-color: #fff; }

.green > .wsmenu-list > li > a {
  color: #424242;
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

.green > .wsmenu-list > li > a {
  background-color: #fff !important; }

.green > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #E5E5E5 !important; }

.green > .wsmenu-list > li:hover > a {
  background: #74a52e !important;
  color: #fff !important; }

.green > .wsmenu-list > li > a:hover {
  background: #74a52e !important;
  color: #fff !important; }

.green > .wsmenu-list > li > a.active {
  background: #74a52e !important;
  color: #fff !important; }

.green > .wsmenu-submenu > li > a:hover {
  background: #74a52e !important;
  border-radius: 0px !important;
  color: #fff !important; }

.green .wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff; }

.green .wsmenu-list li a.active .arrow:after {
  border-top-color: #fff; }

.green .wsmenu-list li:hover > a .arrow:after {
  border-top-color: #fff; }

.red > .wsmenu-list > li > a {
  color: #424242;
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

.red > .wsmenu-list > li > a {
  background-color: #fff !important; }

.red > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #E5E5E5 !important; }

.red > .wsmenu-list > li:hover > a {
  background: #DA4B38 !important;
  color: #fff !important; }

.red > .wsmenu-list > li > a:hover {
  background: #DA4B38 !important;
  color: #fff !important; }

.red > .wsmenu-list > li > a.active {
  background: #DA4B38 !important;
  color: #fff !important; }

.red > .wsmenu-submenu > li > a:hover {
  background: #DA4B38 !important;
  border-radius: 0px !important;
  color: #fff !important; }

.red .wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff; }

.red .wsmenu-list li a.active .arrow:after {
  border-top-color: #fff; }

.red .wsmenu-list li:hover > a .arrow:after {
  border-top-color: #fff; }

.yellow > .wsmenu-list > li > a {
  color: #424242;
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

.yellow > .wsmenu-list > li > a {
  background-color: #fff !important; }

.yellow > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #E5E5E5 !important; }

.yellow > .wsmenu-list > li:hover > a {
  background: #F6BB42 !important;
  color: #fff !important; }

.yellow > .wsmenu-list > li > a:hover {
  background: #F6BB42 !important;
  color: #fff !important; }

.yellow > .wsmenu-list > li > a.active {
  background: #F6BB42 !important;
  color: #fff !important; }

.yellow > .wsmenu-submenu > li > a:hover {
  background: #F6BB42 !important;
  border-radius: 0px !important;
  color: #fff !important; }

.yellow .wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff; }

.yellow .wsmenu-list li a.active .arrow:after {
  border-top-color: #fff; }

.yellow .wsmenu-list li:hover > a .arrow:after {
  border-top-color: #fff; }

.yellow > .wsmenu-list li a .fa {
  color: #424242 !important; }

.purple > .wsmenu-list > li > a {
  color: #424242;
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

.purple > .wsmenu-list > li > a {
  background-color: #fff !important; }

.purple > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #E5E5E5 !important; }

.purple > .wsmenu-list > li:hover > a {
  background: #967ADC !important;
  color: #fff !important; }

.purple > .wsmenu-list > li > a:hover {
  background: #967ADC !important;
  color: #fff !important; }

.purple > .wsmenu-list > li > a.active {
  background: #967ADC !important;
  color: #fff !important; }

.purple > .wsmenu-submenu > li > a:hover {
  background: #967ADC !important;
  border-radius: 0px !important;
  color: #fff !important; }

.purple .wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff; }

.purple .wsmenu-list li a.active .arrow:after {
  border-top-color: #fff; }

.purple .wsmenu-list li:hover > a .arrow:after {
  border-top-color: #fff; }

.pink > .wsmenu-list > li > a {
  color: #424242;
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

.pink > .wsmenu-list > li > a {
  background-color: #fff !important; }

.pink > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #E5E5E5 !important; }

.pink > .wsmenu-list > li:hover > a {
  background: #ee1289 !important;
  color: #fff !important; }

.pink > .wsmenu-list > li > a:hover {
  background: #ee1289 !important;
  color: #fff !important; }

.pink > .wsmenu-list > li > a.active {
  background: #ee1289 !important;
  color: #fff !important; }

.pink > .wsmenu-submenu > li > a:hover {
  background: #ee1289 !important;
  border-radius: 0px !important;
  color: #fff !important; }

.pink .wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff; }

.pink .wsmenu-list li a.active .arrow:after {
  border-top-color: #fff; }

.pink .wsmenu-list li:hover > a .arrow:after {
  border-top-color: #fff; }

.chocolate > .wsmenu-list > li > a {
  color: #424242;
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

.chocolate > .wsmenu-list > li > a {
  background-color: #fff !important; }

.chocolate > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #E5E5E5 !important; }

.chocolate > .wsmenu-list > li:hover > a {
  background: #934915 !important;
  color: #fff !important; }

.chocolate > .wsmenu-list > li a:hover {
  background: #934915 !important;
  color: #fff !important; }

.chocolate > .wsmenu-list > li a.active {
  background: #934915 !important;
  color: #fff !important; }

.chocolate > .wsmenu-submenu > li a:hover {
  background: #934915 !important;
  border-radius: 0px !important;
  color: #fff !important; }

.chocolate .wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff; }

.chocolate .wsmenu-list li a.active .arrow:after {
  border-top-color: #fff; }

.chocolate .wsmenu-list li:hover > a .arrow:after {
  border-top-color: #fff; }

.tranbg {
  background-color: transparent !important; }

.whitebg {
  background-color: #fff !important; }

.whitebg:hover {
  color: #000 !important; }

.blue-grdt {
  background: #5999ee;
  background: -moz-linear-gradient(top, #5999ee 0%, #4a89dc 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5999ee), color-stop(100%, #4a89dc));
  background: -webkit-linear-gradient(top, #5999ee 0%, #4a89dc 100%);
  background: -o-linear-gradient(top, #5999ee 0%, #4a89dc 100%);
  background: -ms-linear-gradient(top, #5999ee 0%, #4a89dc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5999ee', endColorstr='#4a89dc', GradientType=0);
  color: #fff; }

.gry-grdt {
  background: #565656;
  background: -moz-linear-gradient(top, #565656 0%, #424242 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #565656), color-stop(100%, #424242));
  background: -webkit-linear-gradient(top, #565656 0%, #424242 100%);
  background: -o-linear-gradient(top, #565656 0%, #424242 100%);
  background: -ms-linear-gradient(top, #565656 0%, #424242 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#565656', endColorstr='#424242', GradientType=0);
  color: #fff; }

.green-grdt {
  background: #86ba3d;
  background: -moz-linear-gradient(top, #86ba3d 0%, #74a52e 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #86ba3d), color-stop(100%, #74a52e));
  background: -webkit-linear-gradient(top, #86ba3d 0%, #74a52e 100%);
  background: -o-linear-gradient(top, #86ba3d 0%, #74a52e 100%);
  background: -ms-linear-gradient(top, #86ba3d 0%, #74a52e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#86ba3d', endColorstr='#74a52e', GradientType=0);
  color: #fff; }

.red-grdt {
  background: #f05c48;
  background: -moz-linear-gradient(top, #f05c48 0%, #da4b38 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f05c48), color-stop(100%, #da4b38));
  background: -webkit-linear-gradient(top, #f05c48 0%, #da4b38 100%);
  background: -o-linear-gradient(top, #f05c48 0%, #da4b38 100%);
  background: -ms-linear-gradient(top, #f05c48 0%, #da4b38 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f05c48', endColorstr='#da4b38', GradientType=0);
  color: #fff; }

.orange-grdt {
  background: #fc7d33;
  background: -moz-linear-gradient(top, #fc7d33 0%, #ff670f 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fc7d33), color-stop(100%, #ff670f));
  background: -webkit-linear-gradient(top, #fc7d33 0%, #ff670f 100%);
  background: -o-linear-gradient(top, #fc7d33 0%, #ff670f 100%);
  background: -ms-linear-gradient(top, #fc7d33 0%, #ff670f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fc7d33', endColorstr='#ff670f', GradientType=0);
  color: #fff; }

.yellow-grdt {
  background: #ffcd67;
  background: -moz-linear-gradient(top, #ffcd67 0%, #f6bb42 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffcd67), color-stop(100%, #f6bb42));
  background: -webkit-linear-gradient(top, #ffcd67 0%, #ff670f 100%);
  background: -o-linear-gradient(top, #ffcd67 0%, #f6bb42 100%);
  background: -ms-linear-gradient(top, #ffcd67 0%, #f6bb42 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffcd67', endColorstr='#f6bb42', GradientType=0);
  color: #fff; }

.purple-grdt {
  background: #a98ded;
  background: -moz-linear-gradient(top, #a98ded 0%, #967adc 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #a98ded), color-stop(100%, #967adc));
  background: -webkit-linear-gradient(top, #a98ded 0%, #967adc 100%);
  background: -o-linear-gradient(top, #a98ded 0%, #967adc 100%);
  background: -ms-linear-gradient(top, #a98ded 0%, #967adc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a98ded', endColorstr='#967adc', GradientType=0);
  color: #fff; }

.pink-grdt {
  background: #fb3ea4;
  background: -moz-linear-gradient(top, #fb3ea4 0%, #f21b8f 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fb3ea4), color-stop(100%, #f21b8f));
  background: -webkit-linear-gradient(top, #fb3ea4 0%, #f21b8f 100%);
  background: -o-linear-gradient(top, #fb3ea4 0%, #f21b8f 100%);
  background: -ms-linear-gradient(top, #fb3ea4 0%, #f21b8f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fb3ea4', endColorstr='#f21b8f', GradientType=0);
  color: #fff; }

.whitebg > .wsmenu-list > li > a {
  color: #424242;
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

.whitebg > .wsmenu-list > li > a {
  background-color: #fff !important; }

.whitebg > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #E5E5E5 !important; }

.red-grdt > .wsmenu-list > li > a {
  color: #fff !important; }

.red-grdt > .wsmenu-list > li > a {
  background-color: transparent !important; }

.red-grdt > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #fff !important; }

.gry-grdt > .wsmenu-list > li > a {
  color: #fff !important; }

.gry-grdt > .wsmenu-list > li > a {
  background-color: transparent !important; }

.gry-grdt > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #fff !important; }

.blue-grdt > .wsmenu-list > li > a {
  color: #fff !important; }

.blue-grdt > .wsmenu-list > li > a {
  background-color: transparent !important; }

.blue-grdt > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #fff !important; }

.green-grdt > .wsmenu-list > li > a {
  color: #fff !important; }

.green-grdt > .wsmenu-list > li > a {
  background-color: transparent !important; }

.green-grdt > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #fff !important; }

.orange-grdt > .wsmenu-list > li > a {
  color: #fff !important; }

.orange-grdt > .wsmenu-list > li > a {
  background-color: transparent !important; }

.orange-grdt > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #fff !important; }

.yellow-grdt > .wsmenu-list > li > a {
  color: #424242 !important; }

.yellow-grdt > .wsmenu-list > li > a {
  background-color: transparent !important; }

.yellow-grdt > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #424242 !important; }

.yellow-grdt > .wsmenu-list li a .fa {
  color: #424242 !important; }

.purple-grdt > .wsmenu-list > li > a {
  color: #fff !important; }

.purple-grdt > .wsmenu-list > li > a {
  background-color: transparent !important; }

.purple-grdt > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #fff !important; }

.pink-grdt > .wsmenu-list > li > a {
  color: #fff !important; }

.pink-grdt > .wsmenu-list > li > a {
  background-color: transparent !important; }

.pink-grdt > .wsmenu-list > li > a > .arrow:after {
  border-top-color: #fff !important; }

@media only screen and (max-width: 780px) {
  .red-grdt > .wsmenu-list > li > a > .fa {
    color: #fff !important; }
  .blue-grdt > .wsmenu-list > li > a > .fa {
    color: #fff !important; }
  .orange-grdt > .wsmenu-list > li > a > .fa {
    color: #fff !important; }
  .green-grdt > .wsmenu-list > li > a > .fa {
    color: #fff !important; }
  .purple-grdt > .wsmenu-list > li > a > .fa {
    color: #fff !important; }
  .pink-grdt > .wsmenu-list > li > a > .fa {
    color: #fff !important; }
  .gry > .wsmenu-submenu > li:hover > a {
    background-color: #424242 !important;
    color: #fff; }
  .gry > .wsmenu .wsmenu-list > li > a.active {
    color: #fff;
    background-color: #424242; }
  .gry > .wsmenu .wsmenu-list > li > a:hover {
    color: #fff;
    background-color: #424242; }
  .orange > .wsmenu-submenu > li:hover > a {
    background-color: #ff670f !important;
    color: #fff; }
  .orange > .wsmenu > .wsmenu-list > li > a.active {
    color: #fff;
    background-color: #ff670f; }
  .orange > .wsmenu > .wsmenu-list > li > a:hover {
    color: #fff;
    background-color: #ff670f; }
  .blue > .wsmenu-submenu > li:hover > a {
    background-color: #4A89DC !important;
    color: #fff; }
  .blue > .wsmenu > .wsmenu-list > li > a.active {
    color: #fff;
    background-color: #4A89DC; }
  .blue > .wsmenu > .wsmenu-list > li > a:hover {
    color: #fff;
    background-color: #4A89DC; }
  .green > .wsmenu-submenu > li:hover > a {
    background-color: #74a52e !important;
    color: #fff; }
  .green > .wsmenu > .wsmenu-list > li > a.active {
    color: #fff;
    background-color: #74a52e; }
  .green > .wsmenu > .wsmenu-list > li > a:hover {
    color: #fff;
    background-color: #74a52e; }
  .red > .wsmenu-submenu > li:hover > a {
    background-color: #DA4B38 !important;
    color: #fff; }
  .red > .wsmenu > .wsmenu-list > li > a.active {
    color: #fff;
    background-color: #DA4B38; }
  .red > .wsmenu > .wsmenu-list > li > a:hover {
    color: #fff;
    background-color: #DA4B38; }
  .yellow > .wsmenu-submenu > li:hover > a {
    background-color: #F6BB42 !important;
    color: #fff; }
  .yellow > .wsmenu .wsmenu-list > li > a.active {
    color: #fff;
    background-color: #F6BB42; }
  .yellow > .wsmenu .wsmenu-list > li > a:hover {
    color: #fff;
    background-color: #F6BB42; }
  .purple > .wsmenu-submenu > li:hover > a {
    background-color: #967ADC !important;
    color: #fff; }
  .purple > .wsmenu > .wsmenu-list > li > a.active {
    color: #fff;
    background-color: #967ADC; }
  .purple > .wsmenu > .wsmenu-list > li > a:hover {
    color: #fff;
    background-color: #967ADC; }
  .pink > .wsmenu-submenu > li:hover > a {
    background-color: #ee1289 !important;
    color: #fff; }
  .pink > .wsmenu > .wsmenu-list > li > a.active {
    color: #fff;
    background-color: #ee1289; }
  .pink > .wsmenu > .wsmenu-list > li > a:hover {
    color: #fff;
    background-color: #ee1289; }
  .chocolate > .wsmenu-submenu > li:hover > a {
    background-color: #934915 !important;
    color: #fff; }
  .chocolate > .wsmenu > .wsmenu-list > li > a.active {
    color: #fff;
    background-color: #934915; }
  .chocolate > .wsmenu > .wsmenu-list > li > a:hover {
    color: #fff;
    background-color: #934915; }
  .tranbg {
    background-color: #fff !important; }
  .tranbg > .wsmenu-list > li > a {
    color: #000 !important; } }

/*! nouislider - 14.6.0 - 6/27/2020 */
.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative; }

.noUi-base, .noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect, .noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat; }

.noUi-connect {
  height: 100%;
  width: 100%; }

.noUi-origin {
  height: 10%;
  width: 10%; }

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto; }

.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute; }

.noUi-touch-area {
  height: 100%;
  width: 100%; }

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  -webkit-transition: transform .3s;
  transition: transform .3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

.noUi-horizontal {
  height: 18px; }

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px; }

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto; }

.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0,0 3px 6px -5px #BBB; }

.noUi-connects {
  border-radius: 3px; }

.noUi-connect {
  background: #3FB8AF; }

.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF,inset 0 1px 7px #EBEBEB,0 3px 6px -3px #BBB; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFF,inset 0 1px 7px #DDD,0 3px 6px -3px #BBB; }

.noUi-handle:after, .noUi-handle:before {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px; }

.noUi-handle:after {
  left: 17px; }

.noUi-vertical .noUi-handle:after, .noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  top: 17px; }

[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled] .noUi-handle, [disabled].noUi-handle, [disabled].noUi-target {
  cursor: not-allowed; }

.noUi-pips, .noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-sub {
  background: #AAA; }

.noUi-marker-large {
  background: #AAA; }

.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px; }

.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px; }

/**
 * @author zhixin wen <wenzhixin2010@gmail.com>
 */
.ms-parent {
  display: inline-block;
  position: relative;
  vertical-align: middle; }

.ms-choice {
  display: block;
  width: 100%;
  height: 26px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #aaa;
  text-align: left;
  white-space: nowrap;
  line-height: 26px;
  color: #444;
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #fff; }

.ms-choice.disabled {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default; }

.ms-choice > span {
  position: absolute;
  top: 0;
  left: 0;
  right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding-left: 8px; }

.ms-choice > span.placeholder {
  color: #999; }

.ms-choice > div {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 25px; }

.ms-drop {
  width: 100%;
  overflow: hidden;
  display: none;
  margin-top: -1px;
  padding: 0;
  position: absolute;
  z-index: 1000;
  background: #fff;
  color: #000;
  border: 1px solid #aaa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.ms-drop.bottom {
  top: 100%;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15); }

.ms-drop.top {
  bottom: 100%;
  -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15); }

.ms-search {
  display: inline-block;
  margin: 0;
  min-height: 26px;
  padding: 4px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  z-index: 10000; }

.ms-search input {
  width: 100%;
  height: auto !important;
  min-height: 24px;
  padding: 0 20px 0 5px;
  margin: 0;
  outline: 0;
  font-family: sans-serif;
  font-size: 1em;
  border: 1px solid #aaa;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

.ms-search, .ms-search input {
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

.ms-drop ul {
  overflow: auto;
  margin: 0;
  padding: 5px 8px; }

.ms-drop ul > li {
  list-style: none;
  display: list-item;
  background-image: none;
  position: static; }

.ms-drop ul > li .disabled {
  opacity: .35;
  filter: Alpha(Opacity=35); }

.ms-drop ul > li.multiple {
  display: block;
  float: left; }

.ms-drop ul > li.group {
  clear: both; }

.ms-drop ul > li.multiple label {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.ms-drop ul > li label {
  font-weight: normal;
  display: block;
  white-space: nowrap; }

.ms-drop ul > li label.optgroup {
  font-weight: bold; }

.ms-drop input[type="checkbox"] {
  vertical-align: middle; }

.ms-drop .ms-no-results {
  display: none; }

@font-face {
  font-family: 'Quicksand-SemiBold';
  src: url("../fonts/Quicksand-SemiBold.eot");
  src: url("../fonts/Quicksand-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Quicksand-SemiBold.svg#Quicksand-SemiBold") format("svg"), url("../fonts/Quicksand-SemiBold.ttf") format("truetype"), url("../fonts/Quicksand-SemiBold.woff") format("woff"), url("../fonts/Quicksand-SemiBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "PTSans-Regular";
  src: url("../fonts/Quicksand-Regular.eot");
  src: url("../fonts/Quicksand-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Quicksand-Regular.svg#Quicksand-Regular") format("svg"), url("../fonts/Quicksand-Regular.ttf") format("truetype"), url("../fonts/Quicksand-Regular.woff") format("woff"), url("../fonts/Quicksand-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lato-Regular';
  src: url("../fonts/Quicksand-Regular.eot");
  src: url("../fonts/Quicksand-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Quicksand-Regular.svg#Quicksand-Regular") format("svg"), url("../fonts/Quicksand-Regular.ttf") format("truetype"), url("../fonts/Quicksand-Regular.woff") format("woff"), url("../fonts/Quicksand-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "PTSans-Bold";
  src: url("../fonts/Quicksand-Bold.eot");
  src: url("../fonts/Quicksand-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Quicksand-Bold.svg#Quicksand-Bold") format("svg"), url("../fonts/Quicksand-Bold.ttf") format("truetype"), url("../fonts/Quicksand-Bold.woff") format("woff"), url("../fonts/Quicksand-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lato-Black';
  src: url("../fonts/Quicksand-Bold.eot");
  src: url("../fonts/Quicksand-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Quicksand-Bold.svg#Quicksand-Bold") format("svg"), url("../fonts/Quicksand-Bold.ttf") format("truetype"), url("../fonts/Quicksand-Bold.woff") format("woff"), url("../fonts/Quicksand-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

/* déclaration d'une fonction

@mixin truc($variable){
	color:$variable;
}

////* appel de la fonction

.class{
	@include truc(#000);
}

/* déclaration d'une procédure

%ma-fonction{
	color:#000;
}

////* appel de la procédure

.class{
	@extend %ma-fonction;
	border:0;
}


// fonctions déjà existantes
 
 - lighten($color, $amount) : Makes a color lighter.
 - darken($color, $amount) : Makes a color darker.
 - saturate($color, $amount) : Makes a color more saturated.
 - desaturate($color, $amount) : Makes a color less saturated.
 
Le tout sur ce site : http://sass-lang.com/documentation/Sass/Script/Functions.html
 
*/
html {
  font-size: .625rem; }

body {
  font-size: 1.6rem;
  font-family: "PTSans-Regular", sans-serif; }

sup {
  font-size: 60%; }

ul {
  padding-left: 1.8rem;
  list-style: square; }

.invalid {
  color: #9d261d;
  font-weight: bold; }

input.invalid {
  border: 1px solid #9d261d; }

table {
  border-collapse: collapse; }
  table td {
    border: 1px solid black;
    padding: 5px; }

.w-35 {
  width: 35%; }

input[readonly] {
  cursor: not-allowed;
  background-color: #eeeeee;
  border: 1px solid; }

.app-syndic {
  background-color: #ffd900;
  border-radius: 5px;
  color: #fff;
  font-size: 1.3rem;
  transition: all ease-in 0.2s;
  padding: 2px 10px; }
  .app-syndic:hover {
    background-color: #4d4d4d;
    transition: all ease-in 0.2s; }

img,
svg {
  max-width: 100%; }

a img {
  border: 0; }

a {
  text-decoration-skip: ink; }

[class^="ico-"],
[class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icons, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1.5;
  color: inherit;
  text-transform: none;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  [class^="ico-"]::before,
  [class*=" ico-"]::before {
    display: inline-block;
    font-family: icons, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
    text-decoration: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  [class^="ico-"]:hover,
  [class*=" ico-"]:hover {
    text-decoration: none; }

@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format("eot"), url("../fonts/icons.woff2") format("woff2"), url("../fonts/icons.woff") format("woff"), url("../fonts/icons.ttf") format("truetype"), url("../fonts/icons.svg#icons") format("svg"); }

.ico:before {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none; }

.ico-co-proprietaires:before {
  content: "\E001"; }

.ico-co-propriete:before {
  content: "\E002"; }

.ico-creation:before {
  content: "\E003"; }

.ico-gestion-locative:before {
  content: "\E004"; }

.ico-salaries:before {
  content: "\E005"; }

.body {
  overflow: hidden; }

@media (max-width: 767.98px) {
  .category-module {
    top: 160px; } }

.l-header {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 8px 0; }
  .l-header__entete {
    padding-top: 50px;
    padding-bottom: 50px; }
    .l-header__entete .brand img {
      width: 250px; }
    .l-header__entete h2 {
      color: #fff;
      margin: 0;
      text-transform: uppercase;
      font-family: "PTSans-Bold"; }

.footer__row {
  background: #ffd900;
  border-radius: 0 0 18px 18px;
  padding: 15px; }

.moduletable_footer-texte {
  background: rgba(246, 244, 241, 0.85);
  box-sizing: border-box;
  padding: 8px 25px;
  border-radius: 20px;
  margin-top: 15px; }

.contenu {
  clear: both;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px 15px 0 0;
  border: 1px solid #ffd900;
  padding: 15px 25px;
  box-sizing: border-box; }
  .contenu h2 {
    font-size: 1.4em;
    text-transform: uppercase;
    margin: 0; }
  .contenu .item-page .page-header {
    padding: 0;
    margin: 0;
    border: 0; }
    .contenu .item-page .page-header h2 {
      text-transform: uppercase;
      color: #ffd900;
      font-size: 1.65em;
      text-align: center;
      padding-bottom: 25px;
      font-family: Myriad pro, Calibri, Sans-serif;
      line-height: unset !important;
      clear: both; }
      .contenu .item-page .page-header h2 span {
        font-weight: bold; }
    .contenu .item-page .page-header h1 {
      text-transform: uppercase;
      color: #ffd900;
      font-size: 1.65em;
      text-align: center;
      padding-bottom: 25px;
      font-family: Myriad pro, Calibri, Sans-serif;
      line-height: unset !important;
      clear: both; }
      .contenu .item-page .page-header h1 span {
        font-weight: bold; }
  .contenu .item-page div[itemprop="articleBody"] {
    color: #3b3b3b;
    font-size: 1.1em;
    text-align: justify; }
    .contenu .item-page div[itemprop="articleBody"] strong {
      color: #2b2b2b; }
    .contenu .item-page div[itemprop="articleBody"] h2 {
      color: #ffd900;
      font-size: 1.1em;
      padding: 25px 0 8px;
      font-family: Myriad pro, Calibri, Sans-serif;
      line-height: unset !important; }
    .contenu .item-page div[itemprop="articleBody"] a {
      color: #000;
      text-decoration: underline; }
      .contenu .item-page div[itemprop="articleBody"] a.btn {
        background: url(../images/fleche.png) no-repeat 98% 50% #ffd900;
        border-radius: 0;
        border: 0;
        text-shadow: none;
        color: #4d4d4d;
        font-size: 1.2em;
        font-weight: bold;
        padding: 8px 50px 8px 10px;
        text-decoration: none;
        margin: 30px 0;
        opacity: 1; }
        .contenu .item-page div[itemprop="articleBody"] a.btn:hover {
          opacity: 0.8; }
    .contenu .item-page div[itemprop="articleBody"] blockquote {
      border: 0;
      margin: 25px 0;
      padding: 0;
      color: #000; }
  .contenu #releve {
    position: relative; }
    .contenu #releve h2 {
      color: #a5ac2a;
      font-size: 1.1em;
      padding: 25px 0 8px;
      font-family: Myriad pro, Calibri, Sans-serif;
      text-transform: none;
      line-height: unset !important; }
    .contenu #releve h3 {
      line-height: 20px; }
    .contenu #releve th, .contenu #releve .tot1 > td, .contenu #releve .tot2 > td {
      background-color: #c7cf3a; }
    .contenu #releve .tot2 td {
      border-top: none; }
    .contenu #releve > a {
      float: right;
      margin: 0px 5px;
      color: #fff;
      background-color: #c7cf3a;
      padding: 5px 10px;
      border-radius: 5px;
      text-decoration: none; }
      .contenu #releve > a:hover {
        background-color: #444444; }
    .contenu #releve fieldset {
      border: 1px solid grey;
      padding: 10px; }
      .contenu #releve fieldset legend {
        color: #a5ac2a;
        font-size: 1.1em;
        font-family: Myriad pro, Calibri, Sans-serif;
        padding: 0 10px;
        width: auto;
        border: none;
        margin: 0 5px; }
      .contenu #releve fieldset a {
        cursor: pointer;
        display: block;
        background: url("../images/pdf_button.png") no-repeat 0 0;
        padding-left: 20px;
        margin-left: 20px;
        color: #000;
        text-decoration: none; }
        .contenu #releve fieldset a:hover {
          color: #c7cf3a; }

.itemid-112 div[itemprop="articleBody"] ul li {
  margin: 10px 0; }
  .itemid-112 div[itemprop="articleBody"] ul li strong {
    color: #ccae00 !important; }

.page-immo .annonce a {
  background-color: #fff;
  border: 1px solid #737373;
  padding: 8px;
  box-sizing: border-box;
  opacity: 1; }
  .page-immo .annonce a img {
    border: 2px solid #ffd900;
    box-sizing: border-box;
    width: 180px;
    height: 101px; }
  .page-immo .annonce a .description {
    background: none;
    text-align: left;
    color: #3b3b3b;
    position: relative; }
    .page-immo .annonce a .description .intro_annonce {
      display: block;
      background: url(../images/intro.png) no-repeat 95% 50% rgba(0, 0, 0, 0.5);
      height: 25px;
      position: absolute;
      top: -27px;
      left: 2px;
      width: 97%; }
  .page-immo .annonce a:hover {
    opacity: 0.8; }

.page-immo .pagination .pagination-list {
  box-shadow: none; }
  .page-immo .pagination .pagination-list li a {
    border: 0;
    background: none;
    color: #4d4d4d; }
  .page-immo .pagination .pagination-list li span {
    border: 0;
    background: none;
    color: #4d4d4d; }
  .page-immo .pagination .pagination-list li.active span {
    background-color: #ffd900; }

.page-immo.detail-annonce h3 {
  color: #ffd900;
  text-transform: uppercase;
  font-size: 1.4em;
  line-height: 20px;
  margin: 0; }

.page-immo.detail-annonce #prix {
  padding-bottom: 15px; }

.page-immo.detail-annonce .simulation_locataire {
  display: block;
  text-decoration: none;
  float: left;
  background: url(../images/fleche.png) no-repeat 98% 50% #ffd900;
  padding: 8px 50px 8px 12px;
  color: #3b3b3b;
  font-size: 1.4em;
  font-weight: bold;
  opacity: 1; }
  .page-immo.detail-annonce .simulation_locataire:hover {
    opacity: 0.8; }

.page-immo.detail-annonce #annonce {
  padding-top: 20px; }
  .page-immo.detail-annonce #annonce #slider-photos .slick-vertical {
    background-color: #fff;
    border: 1px solid #ffd900;
    border-bottom-width: 30px;
    border-top-width: 30px;
    border-radius: 13px; }

.page-immo.detail-annonce #informations {
  font-weight: bold;
  font-size: 1.4em;
  color: #ffd900;
  text-transform: uppercase; }

.page-immo.detail-annonce #demande a {
  background: url(../images/fleche.png) no-repeat 98% 50% #ffd900;
  border-radius: 0;
  border: 0;
  text-shadow: none;
  color: #4d4d4d;
  font-size: 1.2em;
  font-weight: bold;
  padding: 8px 50px 8px 10px;
  opacity: 1; }
  .page-immo.detail-annonce #demande a:hover {
    opacity: 0.8; }

.page-immo.detail-annonce #details {
  background-color: #ffd900;
  padding: 5px;
  box-sizing: border-box; }
  .page-immo.detail-annonce #details [class*="span"] h4 {
    background-color: #fff;
    padding: 4px 8px;
    margin: 0; }

.page-immo.detail-annonce .btn-return {
  background: url(../images/fleche-gauche.png) no-repeat 5% 50% #ffd900;
  text-decoration: none;
  border-radius: 0;
  border: 0;
  text-shadow: none;
  color: #4d4d4d;
  font-size: 1.2em;
  font-weight: bold;
  padding: 8px 10px 8px 40px;
  box-shadow: none; }

.aside {
  background-color: #fff; }
  .aside__enfant {
    padding: 15px 15px 25px; }
  .aside .moduletable_contact, .aside .moduletable_temoignage, .aside .moduletable_nous-trouver {
    border: 1px solid #cccccc;
    padding: 5px;
    margin-bottom: 15px; }
    .aside .moduletable_contact h2, .aside .moduletable_temoignage h2, .aside .moduletable_nous-trouver h2 {
      text-transform: uppercase;
      font-family: "PTSans-Bold";
      font-size: 1.4rem;
      color: #ffd900;
      background-color: #4d4d4d;
      padding: 10px 20px;
      text-align: center; }
    .aside .moduletable_contact a, .aside .moduletable_temoignage a, .aside .moduletable_nous-trouver a {
      background: url(../images/lien.png) no-repeat 95% center #4d4d4d;
      color: #fff;
      display: block;
      text-transform: uppercase;
      font-size: 1.2rem;
      padding: 5px 12px;
      transition: 0.3s all ease-in-out; }
      .aside .moduletable_contact a:hover, .aside .moduletable_temoignage a:hover, .aside .moduletable_nous-trouver a:hover {
        transition: 0.3s all ease-in-out;
        background: url(../images/lien.png) no-repeat 95% center #000; }
    .aside .moduletable_contact em, .aside .moduletable_temoignage em, .aside .moduletable_nous-trouver em {
      color: #4d4d4d; }
    .aside .moduletable_contact span, .aside .moduletable_temoignage span, .aside .moduletable_nous-trouver span {
      color: #ffd900; }

.l-article {
  background-color: #fff;
  padding: 15px 15px 25px; }
  @media (max-width: 767.98px) {
    .l-article {
      padding: 15px; } }
  .l-article h1 {
    font-family: "PTSans-Bold";
    text-align: center;
    font-size: 2rem;
    color: #4d4d4d; }
  .l-article h2 {
    font-family: "PTSans-Bold";
    text-transform: uppercase;
    font-size: 1.6rem;
    color: #4d4d4d;
    background-color: #cccccc;
    padding: 10px 20px; }
  .l-article h3 {
    font-family: "PTSans-Bold";
    text-transform: uppercase;
    font-size: 1.4rem;
    color: #343434; }
  .l-article ul {
    list-style-type: none;
    padding-left: 30px; }
    .l-article ul li {
      position: relative; }
      .l-article ul li:before {
        content: '';
        position: absolute;
        left: -18px;
        top: 7px;
        width: 10px;
        height: 10px;
        border-radius: 15px;
        background-color: #ffd900; }
  .l-article a {
    color: #4d4d4d;
    text-decoration: underline; }
  .l-article table {
    width: 100%; }
    .l-article table thead td {
      background-color: #f5d000; }
      .l-article table thead td p {
        margin: 0; }
    .l-article table tbody td {
      background-color: #f2f2f2; }
  .l-article em {
    color: #4d4d4d;
    font-size: 1.2rem; }

.l-landing {
  background-color: #fff;
  padding: 15px 15px 25px; }
  @media (max-width: 767.98px) {
    .l-landing {
      padding: 15px; } }
  .l-landing h1 {
    font-family: "PTSans-Bold";
    text-align: center;
    font-size: 2rem;
    color: #4d4d4d; }
    .l-landing h1 span {
      display: block;
      font-size: 1.6rem;
      color: #737373; }
  .l-landing h2 {
    font-family: "PTSans-Bold";
    text-transform: uppercase;
    font-size: 1.6rem;
    color: #4d4d4d;
    background-color: #cccccc;
    padding: 10px 20px; }
  .l-landing h3 {
    font-family: "PTSans-Bold";
    text-transform: uppercase;
    font-size: 1.4rem;
    color: #343434; }
  .l-landing__slogan {
    background-color: #ffd900;
    height: 260px;
    padding: 88px 52px;
    position: relative; }
    .l-landing__slogan h3 {
      font-size: 2.5rem;
      text-align: center;
      line-height: 30px; }
  .l-landing__slider ul li {
    position: absolute !important; }
  @media (min-width: 992px) {
    .l-landing__slider {
      height: 260px; } }
  .l-landing__contact {
    clear: both; }
    @media (max-width: 1199.98px) {
      .l-landing__contact {
        padding-top: 15px; } }
    .l-landing__contact input {
      width: 100%;
      margin-bottom: 10px;
      padding: 2px 10px;
      border: 1px solid #737373; }
      .l-landing__contact input[type="checkbox"] {
        position: relative;
        top: 5px; }
    .l-landing__contact a {
      color: #ffd900; }
    .l-landing__contact button {
      border: 0;
      border-radius: 0;
      text-transform: uppercase;
      font-family: "PTSans-Bold";
      font-size: 1.4rem;
      padding: 10px 20px;
      background-color: #4d4d4d;
      margin-top: 10px;
      color: #fff;
      display: block;
      text-decoration: none;
      transition: 0.3s all ease-in-out; }
      .l-landing__contact button:hover {
        transition: 0.3s all ease-in-out;
        background-color: #ffd900;
        color: #000; }
  @media (max-width: 1199.98px) {
    .l-landing__temoignage div.w-35 {
      position: relative !important;
      width: 100%;
      padding: 10px;
      margin: 10px 10px 0; }
      .l-landing__temoignage div.w-35 br {
        display: none; } }
  .l-landing .uk-accordion {
    padding: 0; }
    .l-landing .uk-accordion > li.uk-open .uk-accordion-title:before {
      content: '';
      position: absolute;
      right: 20px;
      top: 35%;
      width: 20px;
      height: 20px;
      z-index: 1;
      background: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E) 50% 50% no-repeat;
      background-size: 60%;
      border: 2px solid #ffd900;
      padding: 5px; }
    .l-landing .uk-accordion > li:before {
      content: none; }
    .l-landing .uk-accordion > li .uk-accordion-title {
      font-family: "PTSans-Bold";
      text-transform: uppercase;
      font-size: 1.6rem;
      color: #4d4d4d;
      background-color: #cccccc;
      padding: 10px 130px 10px 20px;
      text-decoration: none;
      position: relative;
      min-height: 67px;
      border: 1px solid #cccccc; }
      .l-landing .uk-accordion > li .uk-accordion-title:after {
        content: 'En savoir plus';
        display: table;
        z-index: 0;
        text-transform: initial;
        margin-top: 10px;
        background-color: #fff;
        color: #ffd900;
        padding: 5px 10px;
        transition: 0.3s all ease-in-out; }
      .l-landing .uk-accordion > li .uk-accordion-title:hover:after {
        background-color: #ffd900;
        color: #fff;
        transition: 0.3s all ease-in-out; }
    .l-landing .uk-accordion > li .uk-accordion-content {
      margin: 0;
      border: 1px solid #cccccc;
      border-top: 0;
      padding: 30px 15px; }
      .l-landing .uk-accordion > li .uk-accordion-content a {
        background-color: #333333;
        color: #ffd900;
        text-align: center;
        padding: 13px 40px;
        text-transform: uppercase;
        text-decoration: none;
        font-family: "PTSans-Bold";
        transition: 0.3s all ease-in-out;
        display: table; }
        .l-landing .uk-accordion > li .uk-accordion-content a:hover {
          background-color: #4d4d4d;
          transition: 0.3s all ease-in-out; }
  .l-landing a.btn-contact {
    background-color: #333333;
    color: #ffd900;
    text-align: center;
    padding: 13px 40px;
    text-transform: uppercase;
    text-decoration: none;
    font-family: "PTSans-Bold";
    transition: 0.3s all ease-in-out;
    display: table; }
    .l-landing a.btn-contact:hover {
      background-color: #4d4d4d;
      transition: 0.3s all ease-in-out; }
  .l-landing strong {
    color: #ffd900; }

.l-contact .item-page {
  background-color: #fff;
  padding: 15px 15px 25px;
  color: #4d4d4d; }
  .l-contact .item-page h1 {
    font-family: "PTSans-Bold";
    text-align: center;
    font-size: 2rem;
    color: #4d4d4d; }
  .l-contact .item-page a {
    color: #ffd900;
    text-decoration: underline; }
  .l-contact .item-page form {
    overflow: hidden;
    margin: 0; }
    .l-contact .item-page form .row {
      padding-bottom: 15px; }
    .l-contact .item-page form label {
      display: block; }
    .l-contact .item-page form input {
      width: 100%; }
    .l-contact .item-page form input, .l-contact .item-page form textarea {
      border: 1px solid #4d4d4d; }
    .l-contact .item-page form #jform_envoyer_copie {
      position: relative;
      top: 6px; }
    .l-contact .item-page form .btn-success {
      text-transform: uppercase;
      font-family: "PTSans-Bold";
      font-size: 1.4rem;
      padding: 10px 20px;
      border: 0;
      border-radius: 0;
      background-color: #4d4d4d;
      color: #fff;
      display: block;
      text-decoration: none;
      transition: 0.3s all ease-in-out;
      float: right; }
      .l-contact .item-page form .btn-success:hover {
        transition: 0.3s all ease-in-out;
        background-color: #ffd900;
        color: #000; }

.blog.l-infospratiques {
  background-color: #fff;
  padding: 15px 0 25px 15px; }
  .blog.l-infospratiques .item {
    border-bottom: 1px solid #4d4d4d;
    margin-bottom: 15px;
    padding-bottom: 15px;
    clear: both;
    overflow: hidden; }
  .blog.l-infospratiques .item-image {
    float: left;
    margin-right: 15px; }
    .blog.l-infospratiques .item-image img {
      width: 200px; }
  .blog.l-infospratiques h4 {
    font-family: "PTSans-Bold";
    font-size: 1.6rem;
    color: #4d4d4d;
    background-color: #cccccc;
    padding: 10px 20px; }
  .blog.l-infospratiques ul {
    list-style-type: none;
    padding-left: 30px; }
    .blog.l-infospratiques ul li {
      position: relative; }
      .blog.l-infospratiques ul li:before {
        content: '';
        position: absolute;
        left: -18px;
        top: 7px;
        width: 10px;
        height: 10px;
        border-radius: 15px;
        background-color: #ffd900; }
  .blog.l-infospratiques a {
    color: #4d4d4d;
    text-decoration: underline; }

.l-simulateur__detail {
  background-color: #fff;
  padding: 15px 15px 25px; }

.l-simulateur__en-tete a {
  text-transform: uppercase;
  font-family: "PTSans-Bold";
  font-size: 1.4rem;
  padding: 10px 20px;
  background-color: #4d4d4d;
  color: #fff;
  display: block;
  text-decoration: none;
  transition: 0.3s all ease-in-out; }
  .l-simulateur__en-tete a:hover {
    transition: 0.3s all ease-in-out;
    background-color: #ffd900;
    color: #000; }

.l-simulateur__titre h1 {
  display: block;
  color: #ffd900;
  font-family: "PTSans-Bold";
  padding: 5px 15px;
  margin: 0 0 15px;
  font-size: 2rem;
  text-align: center; }

.l-simulateur__boutons input {
  text-transform: uppercase;
  font-family: "PTSans-Bold";
  font-size: 1.4rem;
  border: 0;
  padding: 10px 20px;
  background-color: #4d4d4d;
  margin: 0 auto 10px;
  color: #fff;
  display: block;
  text-decoration: none;
  transition: 0.3s all ease-in-out; }
  .l-simulateur__boutons input:hover {
    transition: 0.3s all ease-in-out;
    background-color: #ffd900;
    color: #000; }

.l-simulateur__simulation input {
  width: 100%;
  padding: 5px 15px; }

.l-simulateur__simulation ul {
  list-style-type: none;
  padding-left: 30px; }
  .l-simulateur__simulation ul li {
    position: relative; }
    .l-simulateur__simulation ul li:before {
      content: '';
      position: absolute;
      left: -18px;
      top: 7px;
      width: 10px;
      height: 10px;
      border-radius: 15px;
      background-color: #ffd900; }

.l-annonce .uk-slideshow-items {
  min-height: 280px !important;
  height: 484px; }
  @media (max-width: 767.98px) {
    .l-annonce .uk-slideshow-items {
      height: 280px; } }

.l-annonce__detail {
  background-color: #fff;
  padding: 15px 15px 25px; }

.l-annonce__en-tete a {
  text-transform: uppercase;
  font-family: "PTSans-Bold";
  font-size: 1.4rem;
  padding: 10px 20px;
  background-color: #4d4d4d;
  color: #fff;
  display: block;
  text-decoration: none;
  transition: 0.3s all ease-in-out; }
  .l-annonce__en-tete a:hover {
    transition: 0.3s all ease-in-out;
    background-color: #ffd900;
    color: #000; }

.l-annonce__grande {
  height: 484px; }
  @media (max-width: 767.98px) {
    .l-annonce__grande {
      height: 280px;
      background-size: contain; } }

.l-annonce__navigation {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px 15px;
  color: #ffd900; }

.l-annonce__prix {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 24px; }
  @media (max-width: 1199.98px) {
    .l-annonce__prix {
      font-size: 18px; } }

.l-annonce__miniature li {
  margin-top: 15px; }
  .l-annonce__miniature li a {
    height: 96px;
    width: 96px;
    border: 2px solid #000;
    margin: 0 auto; }
    @media (max-width: 1199.98px) {
      .l-annonce__miniature li a {
        height: 60px;
        width: 60px; } }
  .l-annonce__miniature li.uk-active a {
    border: 2px solid #ffd900; }

.l-annonce__titre {
  background-color: #ffd900; }
  .l-annonce__titre h1, .l-annonce__titre strong, .l-annonce__titre span {
    display: block;
    color: #000;
    font-family: "PTSans-Bold";
    padding: 5px 15px; }
  .l-annonce__titre strong, .l-annonce__titre span {
    font-size: 1.6rem; }
  .l-annonce__titre h1 {
    border-bottom: 1px solid #000;
    margin: 0;
    font-size: 2rem; }
  .l-annonce__titre span {
    background-color: #e6c300;
    border-bottom: 1px solid #000; }

.l-annonce__info {
  padding-top: 15px;
  color: #4d4d4d;
  text-align: justify; }

.l-annonce__details {
  padding: 15px 0; }
  .l-annonce__details ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .l-annonce__details ul li {
      font-size: 1.3rem;
      padding-bottom: 10px; }
      .l-annonce__details ul li h4 {
        font-size: 1.5rem;
        font-weight: bold;
        margin: 0; }

.l-annonce__boutons a {
  text-transform: uppercase;
  font-family: "PTSans-Bold";
  font-size: 1.4rem;
  padding: 10px 20px;
  background-color: #4d4d4d;
  margin-bottom: 10px;
  color: #fff;
  display: block;
  text-decoration: none;
  transition: 0.3s all ease-in-out; }
  .l-annonce__boutons a:hover {
    transition: 0.3s all ease-in-out;
    background-color: #ffd900;
    color: #000; }

@media (max-width: 1199.98px) {
  .l-annonce .photos .uk-slideshow-items {
    min-height: 230px !important; } }

.l-annonces {
  padding-top: 30px; }
  .l-annonces h3 {
    background-color: #ffd900;
    text-transform: uppercase;
    font-size: 1.6rem;
    margin: 0;
    padding: 15px;
    font-family: 'Lato-Black'; }
  .l-annonces__detail {
    background-color: #fff;
    padding: 15px 15px 25px; }
  .l-annonces__bien {
    padding: 10px;
    border: 1px solid #cccccc;
    display: block;
    transition: 0.3s all ease-in-out;
    cursor: pointer; }
    .l-annonces__bien:hover {
      text-decoration: none;
      background-color: #fff7cc;
      transition: 0.3s all ease-in-out; }
  .l-annonces__image {
    border: 2px solid #ffd900; }
    .l-annonces__image span {
      font-size: 1.2rem; }
  .l-annonces__lienvisite {
    position: absolute;
    top: 128px;
    left: 32px;
    display: block; }
    .l-annonces__lienvisite span img {
      max-width: 30%; }
    .l-annonces__lienvisite span:hover {
      text-decoration: none;
      color: #fff;
      background-color: #007bff; }
  .l-annonces__texte {
    color: #4d4d4d;
    font-size: 1.4rem;
    text-align: center;
    padding-top: 5px;
    display: block; }
  .l-annonces ul.pagination {
    margin: 0; }
    .l-annonces ul.pagination li {
      margin: 0 5px; }
      .l-annonces ul.pagination li a {
        color: #4d4d4d;
        border: 0;
        padding: 2px 10px; }
      .l-annonces ul.pagination li.active a {
        background-color: #ffd900; }

.view-syndic main .conteneur_login_coproprio {
  display: none; }

.view-syndic main #releve {
  padding: 15px 15px 25px; }

.view-syndic .l-syndic, .view-syndic main {
  background-color: #fff;
  padding: 15px 15px 25px; }
  .view-syndic .l-syndic h1, .view-syndic main h1 {
    font-family: "PTSans-Bold";
    text-align: center;
    font-size: 2rem;
    color: #ffd900; }
  .view-syndic .l-syndic h2, .view-syndic main h2 {
    font-family: "PTSans-Bold";
    text-transform: uppercase;
    font-size: 1.6rem;
    color: #ffd900;
    margin: 0; }
    .view-syndic .l-syndic h2 span, .view-syndic main h2 span {
      color: #4d4d4d; }
  .view-syndic .l-syndic h3, .view-syndic main h3 {
    font-family: "PTSans-Bold";
    text-transform: uppercase;
    font-size: 1.4rem;
    margin: 0;
    color: #ffd900; }
    .view-syndic .l-syndic h3 span, .view-syndic main h3 span {
      color: #4d4d4d; }
  .view-syndic .l-syndic .nav .nav-item, .view-syndic main .nav .nav-item {
    padding-left: 3px; }
  .view-syndic .l-syndic .nav .nav-link, .view-syndic main .nav .nav-link {
    background-color: #4d4d4d;
    color: #fff;
    transition: 0.3s all ease-in-out; }
    .view-syndic .l-syndic .nav .nav-link:hover, .view-syndic main .nav .nav-link:hover {
      background-color: #ffd900;
      color: #4d4d4d;
      transition: 0.3s all ease-in-out; }
  .view-syndic .l-syndic table, .view-syndic main table {
    width: 100%;
    border: 0;
    margin-top: 25px; }
    .view-syndic .l-syndic table thead th, .view-syndic main table thead th {
      background-color: #737373;
      padding: 5px 15px;
      border: 1px solid #000;
      color: #fff; }
    .view-syndic .l-syndic table tbody tr:nth-child(2n) td, .view-syndic main table tbody tr:nth-child(2n) td {
      background-color: white; }
    .view-syndic .l-syndic table tbody tr td, .view-syndic main table tbody tr td {
      background-color: #fff099; }
      .view-syndic .l-syndic table tbody tr td[colspan="2"], .view-syndic main table tbody tr td[colspan="2"] {
        background-color: #fff;
        border: 0; }
  .view-syndic .l-syndic fieldset, .view-syndic main fieldset {
    border: 1px solid #cccccc;
    padding: 15px; }
    .view-syndic .l-syndic fieldset legend, .view-syndic main fieldset legend {
      display: inline-block;
      width: auto;
      max-width: auto;
      padding: 0 15px;
      color: #4d4d4d;
      font-size: 1.6rem;
      position: relative;
      bottom: 2px; }
    .view-syndic .l-syndic fieldset h3, .view-syndic main fieldset h3 {
      padding: 10px 0; }
    .view-syndic .l-syndic fieldset a, .view-syndic main fieldset a {
      display: block;
      margin-left: 15px;
      color: #4d4d4d;
      text-decoration: underline;
      margin-bottom: 3px; }

.moduletable_slider_annonces {
  margin: auto; }

.annonce img {
  display: block;
  width: 100%; }

.annonce a {
  text-decoration: none;
  display: block;
  height: 100%;
  width: 100%;
  color: #000; }

.annonce a:hover {
  background: none; }

.annonce .description {
  display: block;
  background-color: #f0f0f0;
  padding: 5px 0px;
  text-align: center;
  width: 100%; }

.annonce .prix {
  display: inline;
  font-weight: bold;
  width: 100%; }

.accueil .b-immo {
  margin-bottom: 180px; }

.b-immo h3 {
  font-family: "PTSans-Regular";
  font-size: 2rem; }

.b-immo form {
  margin: 0; }

.b-immo input, .b-immo .ms-parent button {
  background-color: #e6e6e6;
  padding: 5px 15px;
  border: 0;
  font-family: "PTSans-Regular"; }

.b-immo input, .b-immo .ms-parent ms-drop {
  background-color: white;
  border: 1px solid #e6e6e6; }

.b-immo .libelle-budget {
  padding-right: 10px;
  position: relative;
  top: 3px; }

.b-immo__libelle {
  position: relative;
  top: 5px; }

.b-immo button[type="submit"] {
  text-transform: uppercase;
  font-family: "PTSans-Bold";
  font-size: 1.4rem;
  padding: 9px 20px 6px;
  background-color: #ffd900;
  border: 0;
  color: #fff;
  display: block;
  text-decoration: none;
  transition: 0.3s all ease-in-out; }
  .b-immo button[type="submit"] span {
    position: relative;
    bottom: 1px; }
  .b-immo button[type="submit"]:hover {
    transition: 0.3s all ease-in-out;
    background-color: #ffd900;
    color: #000; }

.b-immo .ms-parent {
  width: 100% !important; }
  .b-immo .ms-parent button {
    border-radius: 0;
    height: 35px; }
    .b-immo .ms-parent button span {
      top: 4px; }

.b-immo .ms-drop.bottom {
  width: 300px; }
  .b-immo .ms-drop.bottom ul li label input {
    margin-right: 10px;
    position: relative;
    bottom: 2px; }

.b-immo .valeurs-critere {
  position: relative;
  top: 15px; }
  .b-immo .valeurs-critere .noUi-target {
    background: none;
    border: 0;
    border-radius: 0;
    box-shadow: none; }
    .b-immo .valeurs-critere .noUi-target .noUi-base .noUi-connects {
      background-color: #000;
      height: 2px;
      border-radius: 2px; }
      .b-immo .valeurs-critere .noUi-target .noUi-base .noUi-connects .noUi-connect {
        background-color: #000; }
    .b-immo .valeurs-critere .noUi-target .noUi-base .noUi-origin {
      position: absolute; }
      .b-immo .valeurs-critere .noUi-target .noUi-base .noUi-origin .noUi-handle {
        background-color: #ffd900;
        border-radius: 30px;
        width: 15px;
        height: 15px;
        border: 0;
        box-shadow: none;
        cursor: pointer; }
        .b-immo .valeurs-critere .noUi-target .noUi-base .noUi-origin .noUi-handle.noUi-handle-lower {
          right: -15px; }
        .b-immo .valeurs-critere .noUi-target .noUi-base .noUi-origin .noUi-handle.noUi-handle-upper {
          right: -2px; }
        .b-immo .valeurs-critere .noUi-target .noUi-base .noUi-origin .noUi-handle:before, .b-immo .valeurs-critere .noUi-target .noUi-base .noUi-origin .noUi-handle:after {
          display: none; }

.b-menusyndic #syndicslide {
  background-color: #4d4d4d;
  color: #fff;
  display: inline-block;
  padding: 2px 15px;
  font-size: 1.2rem;
  text-decoration: none;
  transition: 0.3s all ease-in-out; }
  .b-menusyndic #syndicslide:hover {
    background-color: #ffd900;
    color: #000;
    transition: 0.3s all ease-in-out; }

.b-immo {
  position: relative;
  background-color: #fff;
  box-sizing: border-box;
  padding: 25px; }
  .b-immo__choix > div {
    width: 200px; }

#wsnavtoggle {
  padding: 0;
  margin: 0;
  position: absolute;
  left: auto;
  right: 0px;
  top: 0px;
  width: 85px;
  height: 28px;
  background-color: #ffd900;
  z-index: 200;
  border-radius: 4px; }
  #wsnavtoggle:after {
    content: 'MENU';
    position: absolute;
    left: 30px;
    top: 1px;
    font-family: 'Lato-Regular'; }
  #wsnavtoggle span {
    top: 13px;
    left: 3px;
    background-color: #000; }
    #wsnavtoggle span:before, #wsnavtoggle span:after {
      background-color: #000; }

@media (max-width: 1199.98px) {
  .mobile-sub {
    background-color: #737373; }
    .mobile-sub li a {
      border-bottom-color: #4d4d4d !important;
      color: #000 !important; }
    .mobile-sub li.active a {
      color: #fff !important; } }

.b-menusocial ul li {
  line-height: 0;
  padding-left: 5px; }
  .b-menusocial ul li img {
    width: 22px; }

.b-menusyndic h3 {
  font-size: 1.3rem;
  margin: 0; }

.b-menusyndic form {
  margin: 0; }

.b-menusyndic label {
  display: none; }

.b-menusyndic input, .b-menusyndic button {
  border: 0; }

.b-menusyndic .champs {
  padding-left: 10px; }
  @media (max-width: 767.98px) {
    .b-menusyndic .champs {
      padding-top: 10px;
      padding-left: 0; } }

.b-menusyndic input {
  background: #f5f5f5;
  font-style: italic;
  color: #000;
  font-size: 1.3rem;
  margin: 0;
  width: 150px;
  padding: 1px 8px;
  box-shadow: none; }

.b-menusyndic button {
  background-color: #ffd900;
  border-radius: 5px;
  color: #fff;
  font-size: 1.3rem;
  transition: all ease-in 0.2s; }
  .b-menusyndic button:hover {
    background-color: #4d4d4d;
    transition: all ease-in 0.2s; }

.b-navigation {
  width: 100%;
  background-color: #ffd900;
  position: relative;
  padding: 15px 20px; }
  @media (max-width: 1199.98px) {
    .b-navigation {
      position: fixed;
      right: 5px;
      top: 5px;
      height: 30px;
      background: none;
      z-index: 200;
      padding: 0;
      overflow: visible; } }
  .b-navigation ul li.active a {
    color: #000; }
  .b-navigation ul li a {
    color: #4d4d4d;
    transition: 0.3s all ease-in-out; }
    .b-navigation ul li a:hover {
      color: #000;
      transition: 0.3s all ease-in-out; }

.b-presentation {
  margin-top: 50px; }
  .b-presentation__texte {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 15px 0;
    border-radius: 4px;
    text-align: justify; }
    .b-presentation__texte h4 {
      font-size: 2rem; }

.b-menufooter {
  display: table;
  margin: auto; }
  .b-menufooter a {
    color: #4d4d4d; }
  .b-menufooter ul li:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 1px;
    background-color: #4d4d4d;
    position: relative;
    bottom: 3px;
    margin: 0 10px; }
  .b-menufooter ul li:first-child:before {
    content: none; }

.b-favorites {
  margin: 0 0 80px 0; }
  .b-favorites .section-heading {
    background-color: #333333;
    color: #ffd900;
    padding: 18px 20px;
    display: block;
    text-transform: uppercase;
    font-family: 'Lato-Black';
    font-size: 1.6rem; }
  .b-favorites__block {
    border: 1px solid #cccccc;
    background-color: #fff;
    text-align: center; }
    .b-favorites__block a img {
      height: 155px;
      width: 225px;
      margin: 10px;
      border: 2px solid #ffd900;
      object-fit: cover; }
    .b-favorites__block a:hover {
      text-decoration: none; }
    .b-favorites__block__description {
      border-radius: 0 0 10px 10px;
      padding: 10px; }
      .b-favorites__block__description span {
        color: #333333; }
      .b-favorites__block__description__head span {
        color: #333333 !important;
        font-family: "PTSans-Regular";
        font-size: 1.6rem;
        letter-spacing: 0;
        line-height: 22px; }

.b-liens {
  padding-top: 70px; }
  .b-liens .mod-articles-category-introtext {
    margin: 0; }
  .b-liens ul {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 35px;
    list-style-type: none; }
    .b-liens ul li {
      text-align: center;
      font-family: "PTSans-Regular";
      font-size: 2rem; }
      .b-liens ul li img {
        margin-bottom: 20px;
        width: 100%; }
      .b-liens ul li a {
        background-color: #333333;
        color: #ffd900;
        font-size: 1.6rem;
        font-family: "PTSans-Bold";
        clear: both;
        display: inline-block;
        padding: 13px 40px;
        margin-top: 20px;
        text-decoration: none;
        transition: 0.3s all ease-in-out; }
        .b-liens ul li a:hover {
          background-color: #4d4d4d;
          transition: 0.3s all ease-in-out; }

.b-enchiffres h2 {
  background-color: #cccccc;
  font-family: "PTSans-Bold";
  color: #333333;
  font-size: 2rem;
  display: block;
  padding: 18px 20px;
  text-transform: uppercase;
  margin-bottom: 25px; }

.b-enchiffres p {
  margin: 0; }

.b-enchiffres ul {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 8% 20% 1fr 1fr 20%;
  gap: 35px;
  list-style-type: none; }
  .b-enchiffres ul li {
    text-align: center;
    font-family: "PTSans-Regular";
    font-size: 1.9rem; }
    .b-enchiffres ul li img {
      height: 70px;
      margin-bottom: 10px; }
    .b-enchiffres ul li strong {
      font-size: 2.5rem;
      font-family: "PTSans-Bold";
      clear: both;
      display: block;
      width: 100%; }

.b-modal-contact h2 {
  color: #000;
  font-family: "PTSans-Bold"; }

.b-modal-contact .formBody {
  float: none; }

.b-modal-contact .rsform-block-sujets label {
  cursor: pointer; }

.b-modal-contact .rsform-block-sujets .formBody {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 15px; }
  .b-modal-contact .rsform-block-sujets .formBody .rsform-checkbox {
    position: relative;
    margin-right: 10px; }

.b-modal-contact textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #4d4d4d; }

.b-modal-contact .rsform-input-box {
  width: 100%; }

.b-modal-contact .rsform-submit-button {
  margin: auto;
  display: table;
  text-transform: uppercase;
  font-family: "PTSans-Bold";
  font-size: 1.4rem;
  padding: 10px 20px;
  background-color: #4d4d4d;
  color: #fff;
  border: 0;
  text-decoration: none;
  transition: 0.3s all ease-in-out; }
  .b-modal-contact .rsform-submit-button:hover {
    transition: 0.3s all ease-in-out;
    background-color: #ffd900;
    color: #000; }

.b-modal-contact .rsform-block-mail label {
  display: none; }

.u-nowrap {
  white-space: nowrap; }

.centpourcent {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw; }

.alert-success, .alert-danger {
  height: 65px !important; }
  .alert-success h4, .alert-danger h4 {
    display: none !important; }
  .alert-success a, .alert-danger a {
    float: right; }

.dropup, .dropdown {
  position: initial !important; }

.dropdown-menu {
  width: 100% !important;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  box-shadow: none;
  background-color: #E4E4E4; }
  .dropdown-menu li {
    float: left; }

.navbar .nav > li > .dropdown-menu:before {
  border: 0px; }

.navbar .nav > li > .dropdown-menu:after {
  border: 0px; }
