/* syntax déclaration de variable

$variable : #000;

*/

$transition: .25s ease-in-out;

// VARIABLE DE TAILLE
$dix: 1.0rem;
$douze: 1.2rem;
$treize: 1.3rem;
$quatorze: 1.4rem;
$quinze: 1.5rem;
$seize: 1.6rem;
$dix-neuf: 1.9rem;
$vingt: 2.0rem;
$vingt-cinq: 2.5rem;
$cinquante-cinq: 5.5rem;
$cent: 10rem;


// - Module bandeau slick
$carousel-height : 500px;

$header : #f9f8f2;
$entete : #f5f5f5;

$vert : #ffd900;
$gris : #4d4d4d;
$gris_clair : #737373;
$gris-claire : #cccccc;
$input : #959595;

$article : #3b3b3b;
$strong : #2b2b2b;

$text-fonce : #333333;
$noir : #000;
$blanc : #fff;
$transition : 0.3s all ease-in-out;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $blanc;
$dark:          $text-fonce;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
  ),
  $theme-colors
);