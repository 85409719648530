.l-annonces{
	padding-top: 30px;
	h3{
		background-color: $vert;
		text-transform: uppercase;
		font-size: $seize;
		margin: 0;
		padding: 15px;
		font-family: 'Lato-Black';
	}
	&__detail{
		background-color: $blanc;
		padding: 15px 15px 25px;
	}
	&__bien{
		padding: 10px;
		border: 1px solid $gris-claire;
		display: block;
		transition: $transition;
		cursor: pointer;
		&:hover{
			text-decoration: none;
			background-color: lighten($vert,40%);
			transition: $transition;
		}
	}
	&__image{
      border: 2px solid $vert;
		span{
			font-size: $douze;
		}
    }

	&__lienvisite {
		position: absolute;
		top: 128px;
		left: 32px;
		display: block;

		span {
			img{
				max-width: 30%;
			}
			&:hover {
				text-decoration: none;
				color: $blanc;
				background-color: $primary;
			}
		}
	}

	&__texte{
		color: $gris;
		font-size: $quatorze;
		text-align: center;
		padding-top: 5px;
		display: block;
	}
	ul.pagination{
		margin: 0;
		li{
			margin: 0 5px;
			a{
				color: $gris;
				border: 0;
				padding: 2px 10px;
			}
			&.active{
				a{
					background-color: $vert;
				}
			}
		}
	}
}