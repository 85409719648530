.b-presentation{
	margin-top: 50px;
	&__texte{
		background-color: rgba($blanc,0.8);
		padding: 15px 0;
		border-radius: 4px;
		text-align: justify;
		h4{
			font-size: $vingt;
		}
	}
}