// position de module en-tete
.l-header{
	background-color: $blanc;
	position: relative;
	z-index: 1;
	padding: 8px 0;
	&__entete{
		padding-top: 50px;
		padding-bottom: 50px;
		.brand{
			img{
				width: 250px;
			}
		}
		h2{
			color: $blanc;
			margin: 0;
			text-transform: uppercase;
			font-family: "PTSans-Bold";
		}
	}
}