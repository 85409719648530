#wsnavtoggle{
  padding: 0;
  margin: 0;
  position: absolute;
  left: auto;
  right: 0px;
  top:0px;
  width: 85px;
  height: 28px;
  background-color: $vert;
  z-index: 200;
  border-radius: 4px;
  &:after{
    content: 'MENU';
    position: absolute;
    left: 30px;
    top: 1px;
    font-family: 'Lato-Regular';
  }
  span{
    top:13px;
    left: 3px;
    background-color: $noir;
    &:before, &:after{
      background-color: $noir;
    }
  }
}

@include media-breakpoint-down(lg) {
  .mobile-sub {
    background-color: $gris_clair;
    li {
      a {
        border-bottom-color: $gris !important;
        color: $noir !important;
      }
      &.active {
        a {
          color: $blanc !important;
        }
      }
    }
  }
}