.u-nowrap {
	white-space: nowrap;
}

// élément qui prend toute la longueur du site, rajouter un .container dans l'élément qui a cette classe
.centpourcent{
	margin-left: -50vw;
	margin-right: -50vw;
	clear: both;
	position: relative;
	right: 50%;
	left: 50%;
	width: 100vw;
}

.alert-success, .alert-danger {
	height: 65px !important;
	h4 {
		display: none !important;
	}
	a {
		float: right;
	}
}


// si le code de menu responsive est en place, alors ce css permet de faire en sorte que le menu secondaire prenne toute la largeur du menu

.dropup, .dropdown{
	position:initial !important;
}

.dropdown-menu{
	width:100% !important;
	border:0px;
	margin:0px;
	border-radius:0px;
	box-shadow:none;
	background-color:#E4E4E4;
	li{
		float:left;
	}
}

.navbar .nav > li > .dropdown-menu:before{
	border:0px;
}

.navbar .nav > li > .dropdown-menu:after{
	border:0px;
}
