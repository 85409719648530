.b-menusocial{
  ul{
    li{
      line-height: 0;
      padding-left: 5px;
      img{
        width: 22px;
      }
    }
  }
}