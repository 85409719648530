html {
  font-size: .625rem; // 1rem = 10px with this line (easier calcul)
}

body {
  font-size: 1.6rem;
  font-family: "PTSans-Regular", sans-serif;
}

sup {
  font-size: 60%;
}

ul {
  padding-left: 1.8rem;
  list-style: square;
}

.invalid {
  color: #9d261d;
  font-weight: bold;
}

input.invalid {
  border: 1px solid #9d261d;
}

table {
  border-collapse: collapse;
  td {
    border: 1px solid black;
    padding: 5px;
  }
}

.w-35{
  width: 35%;
}

input[readonly] {
  cursor: not-allowed;
  background-color: #eeeeee;
  border: 1px solid;
}

.app-syndic {
  background-color: $vert;
  border-radius: 5px;
  color: $blanc;
  font-size: $treize;
  transition: all ease-in 0.2s;
  padding: 2px 10px;
  &:hover {
    background-color: $gris;
    transition: all ease-in 0.2s;
  }
}