.moduletable_slider_annonces {
	margin: auto;
}

.annonce img {
    display: block;
    width: 100%;
}

.annonce a {
    text-decoration: none;
    display: block;
    height: 100%;
    width: 100%;
    color: #000;
}

.annonce a:hover {
    background: none;
}

.annonce .description {
	display: block;
    background-color: #f0f0f0;
	padding: 5px 0px;
	text-align: center;
	width: 100%;
}

.annonce .prix {
    display: inline;
    font-weight: bold;
    width: 100%;
}