.b-liens{
  padding-top: 70px;
  .mod-articles-category-introtext{
    margin: 0;
  }
  ul{
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 35px;
    list-style-type: none;
    li{
      text-align: center;
      font-family: "PTSans-Regular";
      font-size: $vingt;
      img{
        margin-bottom: 20px;
        width: 100%;
      }
      a{
        background-color: $text-fonce;
        color: $vert;
        font-size: $seize;
        font-family: "PTSans-Bold";
        clear: both;
        display: inline-block;
        padding: 13px 40px;
        margin-top: 20px;
        text-decoration: none;
        transition: $transition;
        &:hover{
          background-color: $gris;
          transition: $transition;
        }
      }
    }
  }
}