// css pour bootstrap
/* ====== obligatoire
 */
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";

/* ====== optionnel
 */
// layout
@import "../../../node_modules/bootstrap/scss/grid"; //-> .container[-fluid] .row[-cols][-sm|-md|-lg|-xl][-1 ~ -12] .col[-sm|-md|-lg|-xl][-1 ~ -12][-auto]
// forms
@import "../../../node_modules/bootstrap/scss/alert"; //-> .alert[-primary|-secondary|-succes|-danger|-warning|-info|-light|-dark]
@import "../../../node_modules/bootstrap/scss/badge"; //-> .badge[-primary|-secondary|-succes|-danger|-warning|-info|-light|-dark|-pill]
@import "../../../node_modules/bootstrap/scss/forms"; //-> .form[-group|-control-file] .form-control[-sm|-md|-lg|-xl] .form-check[-input|-label]
@import "../../../node_modules/bootstrap/scss/input-group"; //-> .input-group[-append|-prepend|-text]
@import "../../../node_modules/bootstrap/scss/buttons"; //-> .disabled
@import "../../../node_modules/bootstrap/scss/dropdown"; //-> .disabled
// navs
@import "../../../node_modules/bootstrap/scss/nav"; //-> .nav[-item|-link|-tabs|-pills|-fill|-justified] .active .disabled .tab[-content|-pane]
@import "../../../node_modules/bootstrap/scss/navbar"; //-> .navbar[-brand|-nav|-toggler|-text|-collapse] .form-inline .collapse
@import "../../../node_modules/bootstrap/scss/breadcrumb"; //-> .breadcrumb
@import "../../../node_modules/bootstrap/scss/pagination"; //-> .pagination .page[-item|-link]
// utilities
@import "../../../node_modules/bootstrap/scss/utilities/background";
@import "../../../node_modules/bootstrap/scss/utilities/borders"; //-> .border[-top|-right|-bottom|-left][0] .rounded[-top|-right|-bottom|-left|-circle|-pill|-0]
@import "../../../node_modules/bootstrap/scss/utilities/display"; //-> .d[-sm|-md|-lg|-xl][-none|inline|inline-block|-block|-table|-table-cell|-table-row|-flex|-inline-flex]
@import "../../../node_modules/bootstrap/scss/utilities/flex"; //-> .flex[-sm|-md|-lg|-xl][-row|-column|-fill|-wrap|-nowrap] .justify-content[-sm|-md|-lg|-xl][-start|-end|-center|-between|-around] .align-items[-sm|-md|-lg|-xl][-start|-end|-center|-baseline|-stretch] .align-self[-sm|-md|-lg|-xl][-start|-end|-center|-baseline|-stretch] .flex[-sm|-md|-lg|-xl][-grow|-shrink][-0|-1] .order[-sm|-md|-lg|-xl][-1 ~ -12]
@import "../../../node_modules/bootstrap/scss/utilities/float"; //-> .float[-sm|-md|-lg|-xl][-left|-right|-none]
@import "../../../node_modules/bootstrap/scss/utilities/position"; //-> .position[-static|-relative|-absolute|-fixed|-sticky]
@import "../../../node_modules/bootstrap/scss/utilities/shadows"; //-> .shadow[-none|-sm|-lg]
@import "../../../node_modules/bootstrap/scss/utilities/sizing"; //-> .[w|h][-25|-50|-75|100|-auto] .m[w|h]-auto .min[-vw|-vh]-100 .v[h|w]-100
@import "../../../node_modules/bootstrap/scss/utilities/spacing"; //-> .[m|p][t|b|l|r|x|y][-0 ~ -5|auto|-n1 ~ -n5]
@import "../../../node_modules/bootstrap/scss/utilities/text"; //-> .text-right

// css pour uikit
/* ====== obligatoire
*/
@import "../../../node_modules/uikit/src/scss/variables";
@import "../../../node_modules/uikit/src/scss/mixins";
@import "../../../node_modules/uikit/src/scss/components/variables";
@import "../../../node_modules/uikit/src/scss/components/mixin";
@import "../../../node_modules/uikit/src/scss/components/base";

/* ====== optionnel
*/
// Elements
@import "../../../node_modules/uikit/src/scss/components/icon";
// layout
@import "../../../node_modules/uikit/src/scss/components/grid"; //-> uk-grid="[masonry:true]" uk-height-match="target: > div > .uk-card"
// common
@import "../../../node_modules/uikit/src/scss/components/close"; //-> uk-close
@import "../../../node_modules/uikit/src/scss/components/totop"; //-> uk-totop
@import "../../../node_modules/uikit/src/scss/components/alert"; //-> uk-alert .uk-alert-[primary|success|warning|danger]
@import "../../../node_modules/uikit/src/scss/components/badge"; //-> .uk-badge
@import "../../../node_modules/uikit/src/scss/components/overlay"; //-> .uk-overlay[-default] .uk-inline .uk-position-cover
// navs
@import "../../../node_modules/uikit/src/scss/components/tab"; //-> uk-tab
@import "../../../node_modules/uikit/src/scss/components/slidenav"; //-> uk-slidenav-[next|previous] .uk-slidenav-[large|container]
@import "../../../node_modules/uikit/src/scss/components/dotnav"; //-> .uk-dotnav[-vertical] .uk-active
// javascript
@import "../../../node_modules/uikit/src/scss/components/accordion"; //-> uk-accordion="collapsible:false" .uk-accordion-[title|content]
@import "../../../node_modules/uikit/src/scss/components/dropdown"; //-> uk-dropdown[="mode:click"|="pos: {top|bottom|left|right|center}-{right|left|bottom|top|center}"]
@import "../../../node_modules/uikit/src/scss/components/modal"; //-> uk-modal
@import "../../../node_modules/uikit/src/scss/components/lightbox"; //-> uk-lightbox="animation: [fade|slide|scale]"
@import "../../../node_modules/uikit/src/scss/components/slideshow"; //-> uk-slideshow .uk-slideshow-items
@import "../../../node_modules/uikit/src/scss/components/slider"; //-> uk-slider .uk-slider-items
@import "../../../node_modules/uikit/src/scss/components/sticky"; //-> uk-sticky[="offset: 100"]
@import "../../../node_modules/uikit/src/scss/components/offcanvas"; //-> uk-offcanvas-bar
@import "../../../node_modules/uikit/src/scss/components/switcher"; //-> uk-switcher .uk-switcher
@import "../../../node_modules/uikit/src/scss/components/position"; //-> uk-position
@import "../../../node_modules/uikit/src/scss/components/background"; //-> uk-position
// additional
@import "../../../node_modules/uikit/src/scss/components/notification"; //-> css pour les messages de notifications après validation de formulaire
@import "../../../node_modules/uikit/src/scss/components/tooltip"; //-> uk-tooltip="title: Hello world; pos: [top{-left|right}|bottom{-left|-right}|right|left]"
//@import "../../../node_modules/uikit/src/scss/components/countdown"; à utiliser quand besoin d'un compteur
// utilities
@import "../../../node_modules/uikit/src/scss/components/animation"; //-> .uk-animation-[fade|-scale-up|-scale-down|-slide-top|-slide-bottom|-slide-left|-slide-right][-small|-medium]
@import "../../../node_modules/uikit/src/scss/components/width"; //-> .uk-child-width-[1-{1 ~ 6}|2-{3 ~ 6}][@s|@m|@l] .uk-grid[-column|-row][-small|-medium|-large|-collapse]
@import "../../../node_modules/uikit/src/scss/components/height"; //-> .uk-height-1-1
@import "../../../node_modules/uikit/src/scss/components/transition"; //-> .uk-transition-[fade|-scale-up|-scale-down|-slide-top|-slide-bottom|-slide-left|-slide-right][-small|-medium]
@import "../../../node_modules/uikit/src/scss/components/utility";
@import "../../../node_modules/uikit/src/scss/components/flex";
